.firstaid {
  &-table{
    .row{
      &:first-of-type {
        .col-1{
          display: flex;
          justify-content: center;
          align-items: flex-end;
          padding: 1em 0;
        }
        .vertical-text{
          text-align: left;
          color: initial;
          writing-mode: tb-rl;
          transform: rotate(-180deg);
          max-height: 16em;
        }
      }

      .col-1{
        text-align: center;
        color: green;
      }
    }
    &-true {
      background: url("../img/check.svg") no-repeat center;
    }
  }
}