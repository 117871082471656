$h : header;
$n : nav;

.#{$h}{
  position: sticky;
  z-index: 199;
  top: 0;
  .sticky-show{
    display: none;
  }
  &-middle{
    font-family: $italic;
    padding-left: 0!important;
    p{
      margin: auto;
      //width: 370px;
      font-size: .9rem;
    }
  }
  &-search{
    //max-width: 215px;
    &-form{
      position: relative;
      border: 1px solid $pr-gray-1;
      padding: .2em .5em;
      //width: 250px;
      margin-right: -1em;
    }
    &-input, &-label{
      transform: skew(30deg);
    }
    &-input{
      border: none;
      background: transparent;
    }
    &-label{
      position: absolute;
      right: .5em;
      top: 0;
      &:after{
        display: block;
        content: '';
        background-size: 28px 28px;
        height: 28px;
        width: 28px;
        background: url("../img/svg/search.svg") no-repeat center center / contain;
      }
    }
    &-btn{
      position: absolute;
      top: 0;
      right: 0;
      border: none;
      width: 42px;
      height: 100%;
      background-color: transparent;
    }
  }
  &-ico_tel{
    //margin-right: -.9em;
    .textwidget{
      transform: skew(-30deg);
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $pr-primary;
      min-width: 46px;
      height: 32px;
      svg{
        transform: rotate(360deg) skew(30deg);
      }
    }
  }
  &-lang{
    width: 52px;
    height: 32px;
    select{
      color: $pr-gray-2;
      font-family: $light;
      width: 100%;
      transform: skew(30deg);
      background: transparent;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border: none;
      background-image: url("../img/svg/caret-down.svg");
      background-repeat: no-repeat;
      background-position: 80% center;
      padding-left: .4rem!important;
    }
  }
  .#{$n}{
    &-container{
      z-index: 100;
    }
    &-bar{
      font-family: $condensed;
      font-size: 16px;
      text-align: center;
    }
    &-item{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex-grow: 1;
    }
    &-link{
      color: white;
      padding: .7em 0;
    }
    &-first{
      background-color: $white;
      a{
        color: $pr-secondary;
      }
    }
    &-dropdown{
      margin: 0;
      border: none;
      @for $i from 1 through 9 {
        &.left-#{$i} {
          left: 13.6px*$i;
        }
      }
    }
    &-list-group{
      border-radius: 0;
    }
    &-list-item{
      border:none;
      .#{$n}-link{
        text-align: center;
      }
    }
    &-dropdown-sub{
      margin: 0;
      border: 0;
      left: 100%;
      top: 0;
    }
    //.current-menu-ancestor, .current-menu-item, .current-page-item {
    //  background-color: rgba($pr-secondary, .7);
    //}
  }
}