.youtube {
  background-color: #C2C2C2;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  img {
    width: 100%;
    top: -16.84%;
    left: 0;
    opacity: 0.7;
  }
  .play-button {
    width: 75px;
    height: 50px;
    background-color: $pr-primary;
    box-shadow: 0 0 30px rgba( 0,0,0,0.6 );
    z-index: 1;
    opacity: 0.9;
    &:before {
      content: "";
      border-style: solid;
      border-width: 12px 0 15px 20px;
      border-color: transparent transparent transparent #fff;
    }
  }
  img, .play-button {
    cursor: pointer;
  }
  img, iframe, .play-button, .play-button:before {
    position: absolute;
  }
  .play-button{
    top: 50%;
    left: 50%;
    transform: skew(-30deg) translate3d( -70%, -50%, 0 );
  }
  .play-button:before{
    top: 50%;
    left: 50%;
    transform: skew(30deg) translate3d( 0%, -50%, 0 );
  }
  iframe {
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
  }
}
