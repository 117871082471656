@charset "UTF-8";
/*!
 * Theme Name: Prevor Theme
 * Description: Theme wordpress prevor
 * Author: Les petits koalas roux
*/
/*!
 * Bootstrap Grid v4.6.0 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }

  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }

  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }

  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }

  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.3333333333%;
  }

  .offset-md-2 {
    margin-left: 16.6666666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333333333%;
  }

  .offset-md-5 {
    margin-left: 41.6666666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333333333%;
  }

  .offset-md-8 {
    margin-left: 66.6666666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333333333%;
  }

  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }

  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }

  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }

  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }

  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }

  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }

  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }

  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }

  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
}
.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0,
.my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0,
.mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0,
.my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0,
.mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .mt-sm-1,
.my-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mr-sm-1,
.mx-sm-1 {
    margin-right: 0.25rem !important;
  }

  .mb-sm-1,
.my-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-sm-1,
.mx-sm-1 {
    margin-left: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .mt-sm-2,
.my-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mr-sm-2,
.mx-sm-2 {
    margin-right: 0.5rem !important;
  }

  .mb-sm-2,
.my-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-sm-2,
.mx-sm-2 {
    margin-left: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .mt-sm-3,
.my-sm-3 {
    margin-top: 1rem !important;
  }

  .mr-sm-3,
.mx-sm-3 {
    margin-right: 1rem !important;
  }

  .mb-sm-3,
.my-sm-3 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-3,
.mx-sm-3 {
    margin-left: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .mt-sm-4,
.my-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-4,
.mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-4,
.my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-4,
.mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .mt-sm-5,
.my-sm-5 {
    margin-top: 3rem !important;
  }

  .mr-sm-5,
.mx-sm-5 {
    margin-right: 3rem !important;
  }

  .mb-sm-5,
.my-sm-5 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-5,
.mx-sm-5 {
    margin-left: 3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0,
.py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0,
.px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0,
.py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0,
.px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .pt-sm-1,
.py-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pr-sm-1,
.px-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pb-sm-1,
.py-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-sm-1,
.px-sm-1 {
    padding-left: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .pt-sm-2,
.py-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pr-sm-2,
.px-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pb-sm-2,
.py-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-sm-2,
.px-sm-2 {
    padding-left: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .pt-sm-3,
.py-sm-3 {
    padding-top: 1rem !important;
  }

  .pr-sm-3,
.px-sm-3 {
    padding-right: 1rem !important;
  }

  .pb-sm-3,
.py-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pl-sm-3,
.px-sm-3 {
    padding-left: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .pt-sm-4,
.py-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-4,
.px-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-4,
.py-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-4,
.px-sm-4 {
    padding-left: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .pt-sm-5,
.py-sm-5 {
    padding-top: 3rem !important;
  }

  .pr-sm-5,
.px-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-5,
.py-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-5,
.px-sm-5 {
    padding-left: 3rem !important;
  }

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  .mt-sm-n1,
.my-sm-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-sm-n1,
.mx-sm-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-sm-n1,
.my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-sm-n1,
.mx-sm-n1 {
    margin-left: -0.25rem !important;
  }

  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  .mt-sm-n2,
.my-sm-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-sm-n2,
.mx-sm-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-sm-n2,
.my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-sm-n2,
.mx-sm-n2 {
    margin-left: -0.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .mt-sm-n3,
.my-sm-n3 {
    margin-top: -1rem !important;
  }

  .mr-sm-n3,
.mx-sm-n3 {
    margin-right: -1rem !important;
  }

  .mb-sm-n3,
.my-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-sm-n3,
.mx-sm-n3 {
    margin-left: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .mt-sm-n4,
.my-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-sm-n4,
.mx-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-sm-n4,
.my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-sm-n4,
.mx-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -3rem !important;
  }

  .mt-sm-n5,
.my-sm-n5 {
    margin-top: -3rem !important;
  }

  .mr-sm-n5,
.mx-sm-n5 {
    margin-right: -3rem !important;
  }

  .mb-sm-n5,
.my-sm-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-sm-n5,
.mx-sm-n5 {
    margin-left: -3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto,
.my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto,
.mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto,
.my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto,
.mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0,
.my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0,
.mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0,
.my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0,
.mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .mt-md-1,
.my-md-1 {
    margin-top: 0.25rem !important;
  }

  .mr-md-1,
.mx-md-1 {
    margin-right: 0.25rem !important;
  }

  .mb-md-1,
.my-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-md-1,
.mx-md-1 {
    margin-left: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .mt-md-2,
.my-md-2 {
    margin-top: 0.5rem !important;
  }

  .mr-md-2,
.mx-md-2 {
    margin-right: 0.5rem !important;
  }

  .mb-md-2,
.my-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-md-2,
.mx-md-2 {
    margin-left: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .mt-md-3,
.my-md-3 {
    margin-top: 1rem !important;
  }

  .mr-md-3,
.mx-md-3 {
    margin-right: 1rem !important;
  }

  .mb-md-3,
.my-md-3 {
    margin-bottom: 1rem !important;
  }

  .ml-md-3,
.mx-md-3 {
    margin-left: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .mt-md-4,
.my-md-4 {
    margin-top: 1.5rem !important;
  }

  .mr-md-4,
.mx-md-4 {
    margin-right: 1.5rem !important;
  }

  .mb-md-4,
.my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-4,
.mx-md-4 {
    margin-left: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .mt-md-5,
.my-md-5 {
    margin-top: 3rem !important;
  }

  .mr-md-5,
.mx-md-5 {
    margin-right: 3rem !important;
  }

  .mb-md-5,
.my-md-5 {
    margin-bottom: 3rem !important;
  }

  .ml-md-5,
.mx-md-5 {
    margin-left: 3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0,
.py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0,
.px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0,
.py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0,
.px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .pt-md-1,
.py-md-1 {
    padding-top: 0.25rem !important;
  }

  .pr-md-1,
.px-md-1 {
    padding-right: 0.25rem !important;
  }

  .pb-md-1,
.py-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-md-1,
.px-md-1 {
    padding-left: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .pt-md-2,
.py-md-2 {
    padding-top: 0.5rem !important;
  }

  .pr-md-2,
.px-md-2 {
    padding-right: 0.5rem !important;
  }

  .pb-md-2,
.py-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-md-2,
.px-md-2 {
    padding-left: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .pt-md-3,
.py-md-3 {
    padding-top: 1rem !important;
  }

  .pr-md-3,
.px-md-3 {
    padding-right: 1rem !important;
  }

  .pb-md-3,
.py-md-3 {
    padding-bottom: 1rem !important;
  }

  .pl-md-3,
.px-md-3 {
    padding-left: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .pt-md-4,
.py-md-4 {
    padding-top: 1.5rem !important;
  }

  .pr-md-4,
.px-md-4 {
    padding-right: 1.5rem !important;
  }

  .pb-md-4,
.py-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-4,
.px-md-4 {
    padding-left: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .pt-md-5,
.py-md-5 {
    padding-top: 3rem !important;
  }

  .pr-md-5,
.px-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-5,
.py-md-5 {
    padding-bottom: 3rem !important;
  }

  .pl-md-5,
.px-md-5 {
    padding-left: 3rem !important;
  }

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  .mt-md-n1,
.my-md-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-md-n1,
.mx-md-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-md-n1,
.my-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-md-n1,
.mx-md-n1 {
    margin-left: -0.25rem !important;
  }

  .m-md-n2 {
    margin: -0.5rem !important;
  }

  .mt-md-n2,
.my-md-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-md-n2,
.mx-md-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-md-n2,
.my-md-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-md-n2,
.mx-md-n2 {
    margin-left: -0.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .mt-md-n3,
.my-md-n3 {
    margin-top: -1rem !important;
  }

  .mr-md-n3,
.mx-md-n3 {
    margin-right: -1rem !important;
  }

  .mb-md-n3,
.my-md-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-md-n3,
.mx-md-n3 {
    margin-left: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .mt-md-n4,
.my-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-md-n4,
.mx-md-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-md-n4,
.my-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-md-n4,
.mx-md-n4 {
    margin-left: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -3rem !important;
  }

  .mt-md-n5,
.my-md-n5 {
    margin-top: -3rem !important;
  }

  .mr-md-n5,
.mx-md-n5 {
    margin-right: -3rem !important;
  }

  .mb-md-n5,
.my-md-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-md-n5,
.mx-md-n5 {
    margin-left: -3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto,
.my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto,
.mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto,
.my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto,
.mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0,
.my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0,
.mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0,
.my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0,
.mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .mt-lg-1,
.my-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mr-lg-1,
.mx-lg-1 {
    margin-right: 0.25rem !important;
  }

  .mb-lg-1,
.my-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-lg-1,
.mx-lg-1 {
    margin-left: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .mt-lg-2,
.my-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mr-lg-2,
.mx-lg-2 {
    margin-right: 0.5rem !important;
  }

  .mb-lg-2,
.my-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-lg-2,
.mx-lg-2 {
    margin-left: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .mt-lg-3,
.my-lg-3 {
    margin-top: 1rem !important;
  }

  .mr-lg-3,
.mx-lg-3 {
    margin-right: 1rem !important;
  }

  .mb-lg-3,
.my-lg-3 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-3,
.mx-lg-3 {
    margin-left: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .mt-lg-4,
.my-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-4,
.mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-4,
.my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-4,
.mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .mt-lg-5,
.my-lg-5 {
    margin-top: 3rem !important;
  }

  .mr-lg-5,
.mx-lg-5 {
    margin-right: 3rem !important;
  }

  .mb-lg-5,
.my-lg-5 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-5,
.mx-lg-5 {
    margin-left: 3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0,
.py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0,
.px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0,
.py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0,
.px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .pt-lg-1,
.py-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pr-lg-1,
.px-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pb-lg-1,
.py-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-lg-1,
.px-lg-1 {
    padding-left: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .pt-lg-2,
.py-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pr-lg-2,
.px-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pb-lg-2,
.py-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-lg-2,
.px-lg-2 {
    padding-left: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .pt-lg-3,
.py-lg-3 {
    padding-top: 1rem !important;
  }

  .pr-lg-3,
.px-lg-3 {
    padding-right: 1rem !important;
  }

  .pb-lg-3,
.py-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pl-lg-3,
.px-lg-3 {
    padding-left: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .pt-lg-4,
.py-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-4,
.px-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-4,
.py-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-4,
.px-lg-4 {
    padding-left: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .pt-lg-5,
.py-lg-5 {
    padding-top: 3rem !important;
  }

  .pr-lg-5,
.px-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-5,
.py-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-5,
.px-lg-5 {
    padding-left: 3rem !important;
  }

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  .mt-lg-n1,
.my-lg-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-lg-n1,
.mx-lg-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-lg-n1,
.my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-lg-n1,
.mx-lg-n1 {
    margin-left: -0.25rem !important;
  }

  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  .mt-lg-n2,
.my-lg-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-lg-n2,
.mx-lg-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-lg-n2,
.my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-lg-n2,
.mx-lg-n2 {
    margin-left: -0.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .mt-lg-n3,
.my-lg-n3 {
    margin-top: -1rem !important;
  }

  .mr-lg-n3,
.mx-lg-n3 {
    margin-right: -1rem !important;
  }

  .mb-lg-n3,
.my-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-lg-n3,
.mx-lg-n3 {
    margin-left: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .mt-lg-n4,
.my-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-lg-n4,
.mx-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-lg-n4,
.my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-lg-n4,
.mx-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -3rem !important;
  }

  .mt-lg-n5,
.my-lg-n5 {
    margin-top: -3rem !important;
  }

  .mr-lg-n5,
.mx-lg-n5 {
    margin-right: -3rem !important;
  }

  .mb-lg-n5,
.my-lg-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-lg-n5,
.mx-lg-n5 {
    margin-left: -3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto,
.my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto,
.mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto,
.my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto,
.mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0,
.my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0,
.mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0,
.my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0,
.mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .mt-xl-1,
.my-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mr-xl-1,
.mx-xl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xl-1,
.my-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-xl-1,
.mx-xl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .mt-xl-2,
.my-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mr-xl-2,
.mx-xl-2 {
    margin-right: 0.5rem !important;
  }

  .mb-xl-2,
.my-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xl-2,
.mx-xl-2 {
    margin-left: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .mt-xl-3,
.my-xl-3 {
    margin-top: 1rem !important;
  }

  .mr-xl-3,
.mx-xl-3 {
    margin-right: 1rem !important;
  }

  .mb-xl-3,
.my-xl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xl-3,
.mx-xl-3 {
    margin-left: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .mt-xl-4,
.my-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-4,
.mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-4,
.my-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-4,
.mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .mt-xl-5,
.my-xl-5 {
    margin-top: 3rem !important;
  }

  .mr-xl-5,
.mx-xl-5 {
    margin-right: 3rem !important;
  }

  .mb-xl-5,
.my-xl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-5,
.mx-xl-5 {
    margin-left: 3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0,
.py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0,
.px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0,
.py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0,
.px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .pt-xl-1,
.py-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pr-xl-1,
.px-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xl-1,
.py-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-xl-1,
.px-xl-1 {
    padding-left: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .pt-xl-2,
.py-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pr-xl-2,
.px-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pb-xl-2,
.py-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xl-2,
.px-xl-2 {
    padding-left: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .pt-xl-3,
.py-xl-3 {
    padding-top: 1rem !important;
  }

  .pr-xl-3,
.px-xl-3 {
    padding-right: 1rem !important;
  }

  .pb-xl-3,
.py-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xl-3,
.px-xl-3 {
    padding-left: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .pt-xl-4,
.py-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-4,
.px-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-4,
.py-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-4,
.px-xl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .pt-xl-5,
.py-xl-5 {
    padding-top: 3rem !important;
  }

  .pr-xl-5,
.px-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-5,
.py-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xl-5,
.px-xl-5 {
    padding-left: 3rem !important;
  }

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  .mt-xl-n1,
.my-xl-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-xl-n1,
.mx-xl-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-xl-n1,
.my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-xl-n1,
.mx-xl-n1 {
    margin-left: -0.25rem !important;
  }

  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  .mt-xl-n2,
.my-xl-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-xl-n2,
.mx-xl-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-xl-n2,
.my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-xl-n2,
.mx-xl-n2 {
    margin-left: -0.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .mt-xl-n3,
.my-xl-n3 {
    margin-top: -1rem !important;
  }

  .mr-xl-n3,
.mx-xl-n3 {
    margin-right: -1rem !important;
  }

  .mb-xl-n3,
.my-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-xl-n3,
.mx-xl-n3 {
    margin-left: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .mt-xl-n4,
.my-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-xl-n4,
.mx-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-xl-n4,
.my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xl-n4,
.mx-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -3rem !important;
  }

  .mt-xl-n5,
.my-xl-n5 {
    margin-top: -3rem !important;
  }

  .mr-xl-n5,
.mx-xl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xl-n5,
.my-xl-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-xl-n5,
.mx-xl-n5 {
    margin-left: -3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto,
.my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto,
.mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto,
.my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto,
.mx-xl-auto {
    margin-left: auto !important;
  }
}
@font-face {
  font-family: "roboto-light";
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/Roboto-Light.eot?") format("eot"), url("../fonts/Roboto-Light.woff2") format("woff2"), url("../fonts/Roboto-Light.woff") format("woff"), url("../fonts/Roboto-Light.ttf") format("truetype"), url("../fonts/Roboto-Light.svg#roboto-light") format("svg");
  font-display: swap;
}
@font-face {
  font-family: "roboto";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/Roboto-Regular.eot?") format("eot"), url("../fonts/Roboto-Regular.woff2") format("woff2"), url("../fonts/Roboto-Regular.woff") format("woff"), url("../fonts/Roboto-Regular.ttf") format("truetype"), url("../fonts/Roboto-Regular.svg#roboto") format("svg");
  font-display: swap;
}
@font-face {
  font-family: "roboto-italic";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/Roboto-Italic.eot?") format("eot"), url("../fonts/Roboto-Italic.woff2") format("woff2"), url("../fonts/Roboto-Italic.woff") format("woff"), url("../fonts/Roboto-Italic.ttf") format("truetype"), url("../fonts/Roboto-Italic.svg#roboto-italic") format("svg");
  font-display: swap;
}
@font-face {
  font-family: "roboto-black";
  font-style: normal;
  font-weight: 900;
  src: url("../fonts/Roboto-Black.eot?") format("eot"), url("../fonts/Roboto-Black.woff2") format("woff2"), url("../fonts/Roboto-Black.woff") format("woff"), url("../fonts/Roboto-Black.ttf") format("truetype"), url("../fonts/Roboto-Black.svg#roboto-black") format("svg");
  font-display: swap;
}
@font-face {
  font-family: "roboto-condensed";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/RobotoCondensed-Regular.eot?") format("eot"), url("../fonts/RobotoCondensed-Regular.woff2") format("woff2"), url("../fonts/RobotoCondensed-Regular.woff") format("woff"), url("../fonts/RobotoCondensed-Regular.ttf") format("truetype"), url("../fonts/RobotoCondensed-Regular.svg#roboto-condensed") format("svg");
  font-display: swap;
}
body {
  font-family: "roboto";
  background-color: white;
  font-size: 12px;
  text-rendering: optimizeSpeed;
}
@media (min-width: 768px) {
  body {
    font-size: 14px;
  }
}
@media (min-width: 992px) {
  body {
    font-size: 16px;
  }
}

header, main {
  background-color: white;
}

#content {
  min-height: 55vh;
  margin-top: 90px;
}
#content ul:not(#menu-left):not(.navbar-advantage-nav):not(.hover_well_list) {
  margin: 1em 0;
}

#footer {
  z-index: 1;
  position: relative;
  content-visibility: auto;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "roboto";
  color: #252525;
}
h1.title-blue, h2.title-blue, h3.title-blue, h4.title-blue, h5.title-blue, h6.title-blue {
  color: #2d81b6;
}
h1.title-white, h2.title-white, h3.title-white, h4.title-white, h5.title-white, h6.title-white {
  color: #FFF;
}

h1 {
  margin-bottom: 1.5rem;
  font-size: 2.5em;
}
h1.title-blue {
  font-size: 2em;
}

h2 {
  margin-bottom: 1.5rem;
  font-size: 2em;
}
h2.title-blue {
  font-size: 1.625em;
}

h3 {
  margin-bottom: 1rem;
  font-size: 1.75em;
}
h3.title-blue {
  font-size: 1.375em;
}

h4 {
  margin-bottom: 1rem;
  font-size: 1.5em;
}
h4.title-blue {
  font-size: 1.1875em;
}

h5 {
  margin-bottom: 1rem;
  font-size: 1.25em;
}
h5.title-blue {
  font-size: 1em;
}

h6 {
  margin-bottom: 0.75rem;
  font-size: 1em;
}
h6.title-blue {
  font-size: 0.8125em;
}

select::-ms-expand {
  display: none;
}

a {
  color: #2d81b6;
  text-decoration: none !important;
}
a:hover {
  color: #0072B9;
}

.page-template-basic #content li:not(.nav-item):not(.menu-item), .article-body li {
  margin-bottom: 0.5rem;
}

.hdr-logo-link img {
  max-height: 54px;
}

.btn {
  border-radius: 0;
}

.hpcard p {
  display: none;
}

.grecaptcha-badge {
  visibility: hidden;
}

/**
 * Colors
 */
/**
 * Breakpoints & Media Queries
 */
/**
 * Colors
 */
/**
 * Often re-used variables
 */
/**
 * Grid System.
 * https://make.wordpress.org/design/2019/10/31/proposal-a-consistent-spacing-system-for-wordpress/
 */
/**
 * Breakpoint mixins
 */
/**
 * Long content fade mixin
 *
 * Creates a fading overlay to signify that the content is longer
 * than the space allows.
 */
/**
 * Button states and focus styles
 */
/**
 * Applies editor left position to the selector passed as argument
 */
/**
 * Styles that are reused verbatim in a few places
 */
/**
 * Allows users to opt-out of animations via OS-level preferences.
 */
/**
 * Reset default styles for JavaScript UI based pages.
 * This is a WP-admin agnostic reset
 */
/**
 * Reset the WP Admin page styles for Gutenberg-like pages.
 */
.wp-block-audio figcaption {
  margin-top: 0.5em;
  margin-bottom: 1em;
}

.wp-block-audio audio {
  width: 100%;
  min-width: 300px;
}

.wp-block-button {
  color: #fff;
}

.wp-block-button.aligncenter {
  text-align: center;
}

.wp-block-button.alignright {
  /*rtl:ignore*/
  text-align: right;
}

.wp-block-button__link {
  background-color: #32373c;
  border: none;
  border-radius: 28px;
  box-shadow: none;
  color: inherit;
  cursor: pointer;
  display: inline-block;
  font-size: 18px;
  margin: 0;
  padding: 12px 24px;
  text-align: center;
  text-decoration: none;
  overflow-wrap: break-word;
}

.wp-block-button__link:hover, .wp-block-button__link:focus, .wp-block-button__link:active, .wp-block-button__link:visited {
  color: inherit;
}

.wp-gs .wp-block-button__link:not(.has-background) {
  background-color: var(--wp-block-core-button--color--background, var(--wp-color--primary, #32373c));
}

.is-style-squared .wp-block-button__link {
  border-radius: 0;
}

.no-border-radius.wp-block-button__link {
  border-radius: 0 !important;
}

.is-style-outline {
  color: #32373c;
}

.is-style-outline .wp-block-button__link {
  background-color: transparent;
  border: 2px solid;
}

.wp-block-buttons .wp-block-button {
  display: inline-block;
  margin-right: 8px;
  margin-bottom: 8px;
}

.wp-block-buttons.alignright .wp-block-button {
  margin-right: none;
  margin-left: 8px;
}

.wp-block-buttons.aligncenter {
  text-align: center;
}

.wp-block-calendar {
  text-align: center;
}

.wp-block-calendar th,
.wp-block-calendar tbody td {
  padding: 4px;
  border: 1px solid #e2e4e7;
}

.wp-block-calendar tfoot td {
  border: none;
}

.wp-block-calendar table {
  width: 100%;
  border-collapse: collapse;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}

.wp-block-calendar table th {
  font-weight: 400;
  background: #edeff0;
}

.wp-block-calendar a {
  text-decoration: underline;
}

.wp-block-calendar tfoot a {
  color: #00739c;
}

.wp-block-calendar table tbody,
.wp-block-calendar table caption {
  color: #40464d;
}

.wp-block-categories.alignleft {
  /*rtl:ignore*/
  margin-right: 2em;
}

.wp-block-categories.alignright {
  /*rtl:ignore*/
  margin-left: 2em;
}

.wp-block-columns {
  display: flex;
  margin-bottom: 28px;
  flex-wrap: wrap;
}

@media (min-width: 782px) {
  .wp-block-columns {
    flex-wrap: nowrap;
  }
}
.wp-block-columns.has-background {
  padding: 20px 30px;
}

.wp-block-column {
  flex-grow: 1;
  min-width: 0;
  word-break: break-word;
  overflow-wrap: break-word;
}

@media (max-width: 599px) {
  .wp-block-column {
    flex-basis: 100% !important;
  }
}
@media (min-width: 600px) and (max-width: 781px) {
  .wp-block-column {
    flex-basis: calc(50% - 16px) !important;
    flex-grow: 0;
  }

  .wp-block-column:nth-child(even) {
    margin-left: 32px;
  }
}
@media (min-width: 782px) {
  .wp-block-column {
    flex-basis: 0;
    flex-grow: 1;
  }

  .wp-block-column[style] {
    flex-grow: 0;
  }

  .wp-block-column:not(:first-child) {
    margin-left: 32px;
  }
}
/**
 * All Columns Alignment
 */
.wp-block-columns.are-vertically-aligned-top {
  align-items: flex-start;
}

.wp-block-columns.are-vertically-aligned-center {
  align-items: center;
}

.wp-block-columns.are-vertically-aligned-bottom {
  align-items: flex-end;
}

/**
 * Individual Column Alignment
 */
.wp-block-column.is-vertically-aligned-top {
  align-self: flex-start;
}

.wp-block-column.is-vertically-aligned-center {
  -ms-grid-row-align: center;
  align-self: center;
}

.wp-block-column.is-vertically-aligned-bottom {
  align-self: flex-end;
}

.wp-block-column.is-vertically-aligned-top, .wp-block-column.is-vertically-aligned-center, .wp-block-column.is-vertically-aligned-bottom {
  width: 100%;
}

.wp-block-cover-image,
.wp-block-cover {
  position: relative;
  background-color: #000;
  background-size: cover;
  background-position: center center;
  min-height: 430px;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.wp-block-cover-image.has-parallax,
.wp-block-cover.has-parallax {
  background-attachment: fixed;
}

@supports (-webkit-overflow-scrolling: touch) {
  .wp-block-cover-image.has-parallax,
.wp-block-cover.has-parallax {
    background-attachment: scroll;
  }
}
@media (prefers-reduced-motion: reduce) {
  .wp-block-cover-image.has-parallax,
.wp-block-cover.has-parallax {
    background-attachment: scroll;
  }
}
.wp-block-cover-image.has-background-dim::before,
.wp-block-cover.has-background-dim::before {
  content: "";
  background-color: inherit;
}

.wp-block-cover-image.has-background-dim:not(.has-background-gradient)::before,
.wp-block-cover-image .wp-block-cover__gradient-background,
.wp-block-cover.has-background-dim:not(.has-background-gradient)::before,
.wp-block-cover .wp-block-cover__gradient-background {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
}

.wp-block-cover-image.has-background-dim:not(.has-background-gradient)::before,
.wp-block-cover-image .wp-block-cover__gradient-background,
.wp-block-cover.has-background-dim:not(.has-background-gradient)::before,
.wp-block-cover .wp-block-cover__gradient-background {
  opacity: 0.5;
}

.wp-block-cover-image.has-background-dim.has-background-dim-10:not(.has-background-gradient)::before,
.wp-block-cover.has-background-dim.has-background-dim-10:not(.has-background-gradient)::before {
  opacity: 0.1;
}

.wp-block-cover-image.has-background-dim.has-background-dim-10 .wp-block-cover__gradient-background,
.wp-block-cover.has-background-dim.has-background-dim-10 .wp-block-cover__gradient-background {
  opacity: 0.1;
}

.wp-block-cover-image.has-background-dim.has-background-dim-20:not(.has-background-gradient)::before,
.wp-block-cover.has-background-dim.has-background-dim-20:not(.has-background-gradient)::before {
  opacity: 0.2;
}

.wp-block-cover-image.has-background-dim.has-background-dim-20 .wp-block-cover__gradient-background,
.wp-block-cover.has-background-dim.has-background-dim-20 .wp-block-cover__gradient-background {
  opacity: 0.2;
}

.wp-block-cover-image.has-background-dim.has-background-dim-30:not(.has-background-gradient)::before,
.wp-block-cover.has-background-dim.has-background-dim-30:not(.has-background-gradient)::before {
  opacity: 0.3;
}

.wp-block-cover-image.has-background-dim.has-background-dim-30 .wp-block-cover__gradient-background,
.wp-block-cover.has-background-dim.has-background-dim-30 .wp-block-cover__gradient-background {
  opacity: 0.3;
}

.wp-block-cover-image.has-background-dim.has-background-dim-40:not(.has-background-gradient)::before,
.wp-block-cover.has-background-dim.has-background-dim-40:not(.has-background-gradient)::before {
  opacity: 0.4;
}

.wp-block-cover-image.has-background-dim.has-background-dim-40 .wp-block-cover__gradient-background,
.wp-block-cover.has-background-dim.has-background-dim-40 .wp-block-cover__gradient-background {
  opacity: 0.4;
}

.wp-block-cover-image.has-background-dim.has-background-dim-50:not(.has-background-gradient)::before,
.wp-block-cover.has-background-dim.has-background-dim-50:not(.has-background-gradient)::before {
  opacity: 0.5;
}

.wp-block-cover-image.has-background-dim.has-background-dim-50 .wp-block-cover__gradient-background,
.wp-block-cover.has-background-dim.has-background-dim-50 .wp-block-cover__gradient-background {
  opacity: 0.5;
}

.wp-block-cover-image.has-background-dim.has-background-dim-60:not(.has-background-gradient)::before,
.wp-block-cover.has-background-dim.has-background-dim-60:not(.has-background-gradient)::before {
  opacity: 0.6;
}

.wp-block-cover-image.has-background-dim.has-background-dim-60 .wp-block-cover__gradient-background,
.wp-block-cover.has-background-dim.has-background-dim-60 .wp-block-cover__gradient-background {
  opacity: 0.6;
}

.wp-block-cover-image.has-background-dim.has-background-dim-70:not(.has-background-gradient)::before,
.wp-block-cover.has-background-dim.has-background-dim-70:not(.has-background-gradient)::before {
  opacity: 0.7;
}

.wp-block-cover-image.has-background-dim.has-background-dim-70 .wp-block-cover__gradient-background,
.wp-block-cover.has-background-dim.has-background-dim-70 .wp-block-cover__gradient-background {
  opacity: 0.7;
}

.wp-block-cover-image.has-background-dim.has-background-dim-80:not(.has-background-gradient)::before,
.wp-block-cover.has-background-dim.has-background-dim-80:not(.has-background-gradient)::before {
  opacity: 0.8;
}

.wp-block-cover-image.has-background-dim.has-background-dim-80 .wp-block-cover__gradient-background,
.wp-block-cover.has-background-dim.has-background-dim-80 .wp-block-cover__gradient-background {
  opacity: 0.8;
}

.wp-block-cover-image.has-background-dim.has-background-dim-90:not(.has-background-gradient)::before,
.wp-block-cover.has-background-dim.has-background-dim-90:not(.has-background-gradient)::before {
  opacity: 0.9;
}

.wp-block-cover-image.has-background-dim.has-background-dim-90 .wp-block-cover__gradient-background,
.wp-block-cover.has-background-dim.has-background-dim-90 .wp-block-cover__gradient-background {
  opacity: 0.9;
}

.wp-block-cover-image.has-background-dim.has-background-dim-100:not(.has-background-gradient)::before,
.wp-block-cover.has-background-dim.has-background-dim-100:not(.has-background-gradient)::before {
  opacity: 1;
}

.wp-block-cover-image.has-background-dim.has-background-dim-100 .wp-block-cover__gradient-background,
.wp-block-cover.has-background-dim.has-background-dim-100 .wp-block-cover__gradient-background {
  opacity: 1;
}

.wp-block-cover-image.alignleft, .wp-block-cover-image.alignright,
.wp-block-cover.alignleft,
.wp-block-cover.alignright {
  max-width: 290px;
  width: 100%;
}

.wp-block-cover-image::after,
.wp-block-cover::after {
  display: block;
  content: "";
  font-size: 0;
  min-height: inherit;
}

@supports (position: -webkit-sticky) or (position: sticky) {
  .wp-block-cover-image::after,
.wp-block-cover::after {
    content: none;
  }
}
.wp-block-cover-image.aligncenter, .wp-block-cover-image.alignleft, .wp-block-cover-image.alignright,
.wp-block-cover.aligncenter,
.wp-block-cover.alignleft,
.wp-block-cover.alignright {
  display: flex;
}

.wp-block-cover-image .wp-block-cover__inner-container,
.wp-block-cover .wp-block-cover__inner-container {
  width: calc(100% - 70px);
  z-index: 1;
  color: #f8f9f9;
}

.wp-block-cover-image p:not(.has-text-color),
.wp-block-cover-image h1:not(.has-text-color),
.wp-block-cover-image h2:not(.has-text-color),
.wp-block-cover-image h3:not(.has-text-color),
.wp-block-cover-image h4:not(.has-text-color),
.wp-block-cover-image h5:not(.has-text-color),
.wp-block-cover-image h6:not(.has-text-color),
.wp-block-cover-image .wp-block-subhead:not(.has-text-color),
.wp-block-cover p:not(.has-text-color),
.wp-block-cover h1:not(.has-text-color),
.wp-block-cover h2:not(.has-text-color),
.wp-block-cover h3:not(.has-text-color),
.wp-block-cover h4:not(.has-text-color),
.wp-block-cover h5:not(.has-text-color),
.wp-block-cover h6:not(.has-text-color),
.wp-block-cover .wp-block-subhead:not(.has-text-color) {
  color: inherit;
}

.wp-block-cover__video-background {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 100%;
  height: 100%;
  z-index: 0;
  object-fit: cover;
}

section.wp-block-cover-image h2,
.wp-block-cover-image-text,
.wp-block-cover-text {
  color: #fff;
}

section.wp-block-cover-image h2 a,
section.wp-block-cover-image h2 a:hover,
section.wp-block-cover-image h2 a:focus,
section.wp-block-cover-image h2 a:active,
.wp-block-cover-image-text a,
.wp-block-cover-image-text a:hover,
.wp-block-cover-image-text a:focus,
.wp-block-cover-image-text a:active,
.wp-block-cover-text a,
.wp-block-cover-text a:hover,
.wp-block-cover-text a:focus,
.wp-block-cover-text a:active {
  color: #fff;
}

.wp-block-cover-image .wp-block-cover.has-left-content {
  justify-content: flex-start;
}

.wp-block-cover-image .wp-block-cover.has-right-content {
  justify-content: flex-end;
}

section.wp-block-cover-image.has-left-content > h2,
.wp-block-cover-image.has-left-content .wp-block-cover-image-text,
.wp-block-cover.has-left-content .wp-block-cover-text {
  margin-left: 0;
  text-align: left;
}

section.wp-block-cover-image.has-right-content > h2,
.wp-block-cover-image.has-right-content .wp-block-cover-image-text,
.wp-block-cover.has-right-content .wp-block-cover-text {
  margin-right: 0;
  text-align: right;
}

section.wp-block-cover-image > h2,
.wp-block-cover-image .wp-block-cover-image-text,
.wp-block-cover .wp-block-cover-text {
  font-size: 2em;
  line-height: 1.25;
  z-index: 1;
  margin-bottom: 0;
  max-width: 580px;
  padding: 14px;
  text-align: center;
}

.block-editor-block-list__block[data-type="core/embed"][data-align=left],
.block-editor-block-list__block[data-type="core/embed"][data-align=right],
.wp-block-embed.alignleft,
.wp-block-embed.alignright {
  max-width: 360px;
  width: 100%;
}

.wp-block-embed {
  margin-bottom: 1em;
}

.wp-block-embed figcaption {
  margin-top: 0.5em;
  margin-bottom: 1em;
}

.wp-embed-responsive .wp-block-embed.wp-embed-aspect-21-9 .wp-block-embed__wrapper,
.wp-embed-responsive .wp-block-embed.wp-embed-aspect-18-9 .wp-block-embed__wrapper,
.wp-embed-responsive .wp-block-embed.wp-embed-aspect-16-9 .wp-block-embed__wrapper,
.wp-embed-responsive .wp-block-embed.wp-embed-aspect-4-3 .wp-block-embed__wrapper,
.wp-embed-responsive .wp-block-embed.wp-embed-aspect-1-1 .wp-block-embed__wrapper,
.wp-embed-responsive .wp-block-embed.wp-embed-aspect-9-16 .wp-block-embed__wrapper,
.wp-embed-responsive .wp-block-embed.wp-embed-aspect-1-2 .wp-block-embed__wrapper {
  position: relative;
}

.wp-embed-responsive .wp-block-embed.wp-embed-aspect-21-9 .wp-block-embed__wrapper::before,
.wp-embed-responsive .wp-block-embed.wp-embed-aspect-18-9 .wp-block-embed__wrapper::before,
.wp-embed-responsive .wp-block-embed.wp-embed-aspect-16-9 .wp-block-embed__wrapper::before,
.wp-embed-responsive .wp-block-embed.wp-embed-aspect-4-3 .wp-block-embed__wrapper::before,
.wp-embed-responsive .wp-block-embed.wp-embed-aspect-1-1 .wp-block-embed__wrapper::before,
.wp-embed-responsive .wp-block-embed.wp-embed-aspect-9-16 .wp-block-embed__wrapper::before,
.wp-embed-responsive .wp-block-embed.wp-embed-aspect-1-2 .wp-block-embed__wrapper::before {
  content: "";
  display: block;
  padding-top: 50%;
}

.wp-embed-responsive .wp-block-embed.wp-embed-aspect-21-9 .wp-block-embed__wrapper iframe,
.wp-embed-responsive .wp-block-embed.wp-embed-aspect-18-9 .wp-block-embed__wrapper iframe,
.wp-embed-responsive .wp-block-embed.wp-embed-aspect-16-9 .wp-block-embed__wrapper iframe,
.wp-embed-responsive .wp-block-embed.wp-embed-aspect-4-3 .wp-block-embed__wrapper iframe,
.wp-embed-responsive .wp-block-embed.wp-embed-aspect-1-1 .wp-block-embed__wrapper iframe,
.wp-embed-responsive .wp-block-embed.wp-embed-aspect-9-16 .wp-block-embed__wrapper iframe,
.wp-embed-responsive .wp-block-embed.wp-embed-aspect-1-2 .wp-block-embed__wrapper iframe {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.wp-embed-responsive .wp-block-embed.wp-embed-aspect-21-9 .wp-block-embed__wrapper::before {
  padding-top: 42.85%;
}

.wp-embed-responsive .wp-block-embed.wp-embed-aspect-18-9 .wp-block-embed__wrapper::before {
  padding-top: 50%;
}

.wp-embed-responsive .wp-block-embed.wp-embed-aspect-16-9 .wp-block-embed__wrapper::before {
  padding-top: 56.25%;
}

.wp-embed-responsive .wp-block-embed.wp-embed-aspect-4-3 .wp-block-embed__wrapper::before {
  padding-top: 75%;
}

.wp-embed-responsive .wp-block-embed.wp-embed-aspect-1-1 .wp-block-embed__wrapper::before {
  padding-top: 100%;
}

.wp-embed-responsive .wp-block-embed.wp-embed-aspect-9-16 .wp-block-embed__wrapper::before {
  padding-top: 177.78%;
}

.wp-embed-responsive .wp-block-embed.wp-embed-aspect-1-2 .wp-block-embed__wrapper::before {
  padding-top: 200%;
}

.wp-block-file {
  margin-bottom: 1.5em;
}

.wp-block-file.aligncenter {
  text-align: center;
}

.wp-block-file.alignright {
  /*rtl:ignore*/
  text-align: right;
}

.wp-block-file .wp-block-file__button {
  background: #32373c;
  border-radius: 2em;
  color: #fff;
  font-size: 13px;
  padding: 0.5em 1em;
}

.wp-block-file a.wp-block-file__button {
  text-decoration: none;
}

.wp-block-file a.wp-block-file__button:hover, .wp-block-file a.wp-block-file__button:visited, .wp-block-file a.wp-block-file__button:focus, .wp-block-file a.wp-block-file__button:active {
  box-shadow: none;
  color: #fff;
  opacity: 0.85;
  text-decoration: none;
}

.wp-block-file * + .wp-block-file__button {
  margin-left: 0.75em;
}

.wp-block-gallery,
.blocks-gallery-grid {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.wp-block-gallery .blocks-gallery-image,
.wp-block-gallery .blocks-gallery-item,
.blocks-gallery-grid .blocks-gallery-image,
.blocks-gallery-grid .blocks-gallery-item {
  margin: 0 16px 16px 0;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.wp-block-gallery .blocks-gallery-image figure,
.wp-block-gallery .blocks-gallery-item figure,
.blocks-gallery-grid .blocks-gallery-image figure,
.blocks-gallery-grid .blocks-gallery-item figure {
  margin: 0;
  height: 100%;
}

@supports (position: -webkit-sticky) or (position: sticky) {
  .wp-block-gallery .blocks-gallery-image figure,
.wp-block-gallery .blocks-gallery-item figure,
.blocks-gallery-grid .blocks-gallery-image figure,
.blocks-gallery-grid .blocks-gallery-item figure {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
  }
}
.wp-block-gallery .blocks-gallery-image img,
.wp-block-gallery .blocks-gallery-item img,
.blocks-gallery-grid .blocks-gallery-image img,
.blocks-gallery-grid .blocks-gallery-item img {
  display: block;
  max-width: 100%;
  height: auto;
}

.wp-block-gallery .blocks-gallery-image img,
.wp-block-gallery .blocks-gallery-item img,
.blocks-gallery-grid .blocks-gallery-image img,
.blocks-gallery-grid .blocks-gallery-item img {
  width: 100%;
}

@supports (position: -webkit-sticky) or (position: sticky) {
  .wp-block-gallery .blocks-gallery-image img,
.wp-block-gallery .blocks-gallery-item img,
.blocks-gallery-grid .blocks-gallery-image img,
.blocks-gallery-grid .blocks-gallery-item img {
    width: auto;
  }
}
.wp-block-gallery .blocks-gallery-image figcaption,
.wp-block-gallery .blocks-gallery-item figcaption,
.blocks-gallery-grid .blocks-gallery-image figcaption,
.blocks-gallery-grid .blocks-gallery-item figcaption {
  position: absolute;
  bottom: 0;
  width: 100%;
  max-height: 100%;
  overflow: auto;
  padding: 40px 10px 9px;
  color: #fff;
  text-align: center;
  font-size: 13px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.7) 0, rgba(0, 0, 0, 0.3) 70%, transparent);
}

.wp-block-gallery .blocks-gallery-image figcaption img,
.wp-block-gallery .blocks-gallery-item figcaption img,
.blocks-gallery-grid .blocks-gallery-image figcaption img,
.blocks-gallery-grid .blocks-gallery-item figcaption img {
  display: inline;
}

.wp-block-gallery.is-cropped .blocks-gallery-image a,
.wp-block-gallery.is-cropped .blocks-gallery-image img,
.wp-block-gallery.is-cropped .blocks-gallery-item a,
.wp-block-gallery.is-cropped .blocks-gallery-item img,
.blocks-gallery-grid.is-cropped .blocks-gallery-image a,
.blocks-gallery-grid.is-cropped .blocks-gallery-image img,
.blocks-gallery-grid.is-cropped .blocks-gallery-item a,
.blocks-gallery-grid.is-cropped .blocks-gallery-item img {
  width: 100%;
}

@supports (position: -webkit-sticky) or (position: sticky) {
  .wp-block-gallery.is-cropped .blocks-gallery-image a,
.wp-block-gallery.is-cropped .blocks-gallery-image img,
.wp-block-gallery.is-cropped .blocks-gallery-item a,
.wp-block-gallery.is-cropped .blocks-gallery-item img,
.blocks-gallery-grid.is-cropped .blocks-gallery-image a,
.blocks-gallery-grid.is-cropped .blocks-gallery-image img,
.blocks-gallery-grid.is-cropped .blocks-gallery-item a,
.blocks-gallery-grid.is-cropped .blocks-gallery-item img {
    height: 100%;
    flex: 1;
    object-fit: cover;
  }
}
.wp-block-gallery .blocks-gallery-image,
.wp-block-gallery .blocks-gallery-item,
.blocks-gallery-grid .blocks-gallery-image,
.blocks-gallery-grid .blocks-gallery-item {
  width: calc((100% - 16px) / 2);
}

.wp-block-gallery .blocks-gallery-image:nth-of-type(even),
.wp-block-gallery .blocks-gallery-item:nth-of-type(even),
.blocks-gallery-grid .blocks-gallery-image:nth-of-type(even),
.blocks-gallery-grid .blocks-gallery-item:nth-of-type(even) {
  margin-right: 0;
}

.wp-block-gallery.columns-1 .blocks-gallery-image,
.wp-block-gallery.columns-1 .blocks-gallery-item,
.blocks-gallery-grid.columns-1 .blocks-gallery-image,
.blocks-gallery-grid.columns-1 .blocks-gallery-item {
  width: 100%;
  margin-right: 0;
}

@media (min-width: 600px) {
  .wp-block-gallery.columns-3 .blocks-gallery-image,
.wp-block-gallery.columns-3 .blocks-gallery-item,
.blocks-gallery-grid.columns-3 .blocks-gallery-image,
.blocks-gallery-grid.columns-3 .blocks-gallery-item {
    width: calc((100% - 32px) / 3);
    margin-right: 16px;
  }

  @supports (-ms-ime-align: auto) {
    .wp-block-gallery.columns-3 .blocks-gallery-image,
.wp-block-gallery.columns-3 .blocks-gallery-item,
.blocks-gallery-grid.columns-3 .blocks-gallery-image,
.blocks-gallery-grid.columns-3 .blocks-gallery-item {
      width: calc((100% - 32px) / 3 - 1px);
    }
  }
  .wp-block-gallery.columns-4 .blocks-gallery-image,
.wp-block-gallery.columns-4 .blocks-gallery-item,
.blocks-gallery-grid.columns-4 .blocks-gallery-image,
.blocks-gallery-grid.columns-4 .blocks-gallery-item {
    width: calc((100% - 48px) / 4);
    margin-right: 16px;
  }

  @supports (-ms-ime-align: auto) {
    .wp-block-gallery.columns-4 .blocks-gallery-image,
.wp-block-gallery.columns-4 .blocks-gallery-item,
.blocks-gallery-grid.columns-4 .blocks-gallery-image,
.blocks-gallery-grid.columns-4 .blocks-gallery-item {
      width: calc((100% - 48px) / 4 - 1px);
    }
  }
  .wp-block-gallery.columns-5 .blocks-gallery-image,
.wp-block-gallery.columns-5 .blocks-gallery-item,
.blocks-gallery-grid.columns-5 .blocks-gallery-image,
.blocks-gallery-grid.columns-5 .blocks-gallery-item {
    width: calc((100% - 64px) / 5);
    margin-right: 16px;
  }

  @supports (-ms-ime-align: auto) {
    .wp-block-gallery.columns-5 .blocks-gallery-image,
.wp-block-gallery.columns-5 .blocks-gallery-item,
.blocks-gallery-grid.columns-5 .blocks-gallery-image,
.blocks-gallery-grid.columns-5 .blocks-gallery-item {
      width: calc((100% - 64px) / 5 - 1px);
    }
  }
  .wp-block-gallery.columns-6 .blocks-gallery-image,
.wp-block-gallery.columns-6 .blocks-gallery-item,
.blocks-gallery-grid.columns-6 .blocks-gallery-image,
.blocks-gallery-grid.columns-6 .blocks-gallery-item {
    width: calc((100% - 80px) / 6);
    margin-right: 16px;
  }

  @supports (-ms-ime-align: auto) {
    .wp-block-gallery.columns-6 .blocks-gallery-image,
.wp-block-gallery.columns-6 .blocks-gallery-item,
.blocks-gallery-grid.columns-6 .blocks-gallery-image,
.blocks-gallery-grid.columns-6 .blocks-gallery-item {
      width: calc((100% - 80px) / 6 - 1px);
    }
  }
  .wp-block-gallery.columns-7 .blocks-gallery-image,
.wp-block-gallery.columns-7 .blocks-gallery-item,
.blocks-gallery-grid.columns-7 .blocks-gallery-image,
.blocks-gallery-grid.columns-7 .blocks-gallery-item {
    width: calc((100% - 96px) / 7);
    margin-right: 16px;
  }

  @supports (-ms-ime-align: auto) {
    .wp-block-gallery.columns-7 .blocks-gallery-image,
.wp-block-gallery.columns-7 .blocks-gallery-item,
.blocks-gallery-grid.columns-7 .blocks-gallery-image,
.blocks-gallery-grid.columns-7 .blocks-gallery-item {
      width: calc((100% - 96px) / 7 - 1px);
    }
  }
  .wp-block-gallery.columns-8 .blocks-gallery-image,
.wp-block-gallery.columns-8 .blocks-gallery-item,
.blocks-gallery-grid.columns-8 .blocks-gallery-image,
.blocks-gallery-grid.columns-8 .blocks-gallery-item {
    width: calc((100% - 112px) / 8);
    margin-right: 16px;
  }

  @supports (-ms-ime-align: auto) {
    .wp-block-gallery.columns-8 .blocks-gallery-image,
.wp-block-gallery.columns-8 .blocks-gallery-item,
.blocks-gallery-grid.columns-8 .blocks-gallery-image,
.blocks-gallery-grid.columns-8 .blocks-gallery-item {
      width: calc((100% - 112px) / 8 - 1px);
    }
  }
  .wp-block-gallery.columns-1 .blocks-gallery-image:nth-of-type(1n),
.wp-block-gallery.columns-1 .blocks-gallery-item:nth-of-type(1n),
.blocks-gallery-grid.columns-1 .blocks-gallery-image:nth-of-type(1n),
.blocks-gallery-grid.columns-1 .blocks-gallery-item:nth-of-type(1n) {
    margin-right: 0;
  }

  .wp-block-gallery.columns-2 .blocks-gallery-image:nth-of-type(2n),
.wp-block-gallery.columns-2 .blocks-gallery-item:nth-of-type(2n),
.blocks-gallery-grid.columns-2 .blocks-gallery-image:nth-of-type(2n),
.blocks-gallery-grid.columns-2 .blocks-gallery-item:nth-of-type(2n) {
    margin-right: 0;
  }

  .wp-block-gallery.columns-3 .blocks-gallery-image:nth-of-type(3n),
.wp-block-gallery.columns-3 .blocks-gallery-item:nth-of-type(3n),
.blocks-gallery-grid.columns-3 .blocks-gallery-image:nth-of-type(3n),
.blocks-gallery-grid.columns-3 .blocks-gallery-item:nth-of-type(3n) {
    margin-right: 0;
  }

  .wp-block-gallery.columns-4 .blocks-gallery-image:nth-of-type(4n),
.wp-block-gallery.columns-4 .blocks-gallery-item:nth-of-type(4n),
.blocks-gallery-grid.columns-4 .blocks-gallery-image:nth-of-type(4n),
.blocks-gallery-grid.columns-4 .blocks-gallery-item:nth-of-type(4n) {
    margin-right: 0;
  }

  .wp-block-gallery.columns-5 .blocks-gallery-image:nth-of-type(5n),
.wp-block-gallery.columns-5 .blocks-gallery-item:nth-of-type(5n),
.blocks-gallery-grid.columns-5 .blocks-gallery-image:nth-of-type(5n),
.blocks-gallery-grid.columns-5 .blocks-gallery-item:nth-of-type(5n) {
    margin-right: 0;
  }

  .wp-block-gallery.columns-6 .blocks-gallery-image:nth-of-type(6n),
.wp-block-gallery.columns-6 .blocks-gallery-item:nth-of-type(6n),
.blocks-gallery-grid.columns-6 .blocks-gallery-image:nth-of-type(6n),
.blocks-gallery-grid.columns-6 .blocks-gallery-item:nth-of-type(6n) {
    margin-right: 0;
  }

  .wp-block-gallery.columns-7 .blocks-gallery-image:nth-of-type(7n),
.wp-block-gallery.columns-7 .blocks-gallery-item:nth-of-type(7n),
.blocks-gallery-grid.columns-7 .blocks-gallery-image:nth-of-type(7n),
.blocks-gallery-grid.columns-7 .blocks-gallery-item:nth-of-type(7n) {
    margin-right: 0;
  }

  .wp-block-gallery.columns-8 .blocks-gallery-image:nth-of-type(8n),
.wp-block-gallery.columns-8 .blocks-gallery-item:nth-of-type(8n),
.blocks-gallery-grid.columns-8 .blocks-gallery-image:nth-of-type(8n),
.blocks-gallery-grid.columns-8 .blocks-gallery-item:nth-of-type(8n) {
    margin-right: 0;
  }
}
.wp-block-gallery .blocks-gallery-image:last-child,
.wp-block-gallery .blocks-gallery-item:last-child,
.blocks-gallery-grid .blocks-gallery-image:last-child,
.blocks-gallery-grid .blocks-gallery-item:last-child {
  margin-right: 0;
}

.wp-block-gallery.alignleft, .wp-block-gallery.alignright,
.blocks-gallery-grid.alignleft,
.blocks-gallery-grid.alignright {
  max-width: 290px;
  width: 100%;
}

.wp-block-gallery.aligncenter .blocks-gallery-item figure,
.blocks-gallery-grid.aligncenter .blocks-gallery-item figure {
  justify-content: center;
}

.wp-block-image {
  margin-bottom: 1em;
}

.wp-block-image img {
  max-width: 100%;
}

.wp-block-image.aligncenter {
  text-align: center;
}

.wp-block-image.alignfull img,
.wp-block-image.alignwide img {
  width: 100%;
}

.wp-block-image .alignleft,
.wp-block-image .alignright,
.wp-block-image .aligncenter, .wp-block-image.is-resized {
  display: table;
  margin-left: 0;
  margin-right: 0;
}

.wp-block-image .alignleft > figcaption,
.wp-block-image .alignright > figcaption,
.wp-block-image .aligncenter > figcaption, .wp-block-image.is-resized > figcaption {
  display: table-caption;
  caption-side: bottom;
}

.wp-block-image .alignleft {
  /*rtl:ignore*/
  float: left;
  /*rtl:ignore*/
  margin-right: 1em;
}

.wp-block-image .alignright {
  /*rtl:ignore*/
  float: right;
  /*rtl:ignore*/
  margin-left: 1em;
}

.wp-block-image .aligncenter {
  margin-left: auto;
  margin-right: auto;
}

.wp-block-image figcaption {
  margin-top: 0.5em;
  margin-bottom: 1em;
}

.is-style-rounded img {
  border-radius: 9999px;
}

.is-style-circle-mask img {
  border-radius: 9999px;
}

@supports (-webkit-mask-image: none) or (mask-image: none) or (-webkit-mask-image: none) {
  .is-style-circle-mask img {
    /* stylelint-disable */
    -webkit-mask-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"><circle cx="50" cy="50" r="50"/></svg>');
    mask-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"><circle cx="50" cy="50" r="50"/></svg>');
    /* stylelint-enable */
    mask-mode: alpha;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: contain;
    mask-size: contain;
    -webkit-mask-position: center;
    mask-position: center;
    border-radius: 0;
  }
}
.wp-block-latest-comments__comment {
  font-size: 15px;
  line-height: 1.1;
  list-style: none;
  margin-bottom: 1em;
}

.has-avatars .wp-block-latest-comments__comment {
  min-height: 36px;
  list-style: none;
}

.has-avatars .wp-block-latest-comments__comment .wp-block-latest-comments__comment-meta,
.has-avatars .wp-block-latest-comments__comment .wp-block-latest-comments__comment-excerpt {
  margin-left: 52px;
}

.has-dates .wp-block-latest-comments__comment,
.has-excerpts .wp-block-latest-comments__comment {
  line-height: 1.5;
}

.wp-block-latest-comments__comment-excerpt p {
  font-size: 14px;
  line-height: 1.8;
  margin: 5px 0 20px;
}

.wp-block-latest-comments__comment-date {
  color: #8f98a1;
  display: block;
  font-size: 12px;
}

.wp-block-latest-comments .avatar,
.wp-block-latest-comments__comment-avatar {
  border-radius: 24px;
  display: block;
  float: left;
  height: 40px;
  margin-right: 12px;
  width: 40px;
}

.wp-block-latest-posts.alignleft {
  /*rtl:ignore*/
  margin-right: 2em;
}

.wp-block-latest-posts.alignright {
  /*rtl:ignore*/
  margin-left: 2em;
}

.wp-block-latest-posts.wp-block-latest-posts__list {
  list-style: none;
}

.wp-block-latest-posts.wp-block-latest-posts__list li {
  clear: both;
}

.wp-block-latest-posts.is-grid {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
}

.wp-block-latest-posts.is-grid li {
  margin: 0 16px 16px 0;
  width: 100%;
}

@media (min-width: 600px) {
  .wp-block-latest-posts.columns-2 li {
    width: calc(50% - 16px);
  }

  .wp-block-latest-posts.columns-3 li {
    width: calc(33.3333333333% - 16px);
  }

  .wp-block-latest-posts.columns-4 li {
    width: calc(25% - 16px);
  }

  .wp-block-latest-posts.columns-5 li {
    width: calc(20% - 16px);
  }

  .wp-block-latest-posts.columns-6 li {
    width: calc(16.6666666667% - 16px);
  }
}
.wp-block-latest-posts__post-date {
  display: block;
  color: #6c7781;
  font-size: 13px;
}

.wp-block-latest-posts__post-excerpt {
  margin-top: 8px;
  margin-bottom: 16px;
}

.wp-block-latest-posts__featured-image img {
  height: auto;
  width: auto;
}

.wp-block-latest-posts__featured-image.alignleft {
  /*rtl:ignore*/
  margin-right: 1em;
}

.wp-block-latest-posts__featured-image.alignright {
  /*rtl:ignore*/
  margin-left: 1em;
}

.wp-block-latest-posts__featured-image.aligncenter {
  margin-bottom: 1em;
  text-align: center;
}

.wp-block-media-text {
  /*!rtl:begin:ignore*/
  direction: ltr;
  /*!rtl:end:ignore*/
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 50% 1fr;
  grid-template-columns: 50% 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.wp-block-media-text.has-media-on-the-right {
  -ms-grid-columns: 1fr 50%;
  grid-template-columns: 1fr 50%;
}

.wp-block-media-text.is-vertically-aligned-top .wp-block-media-text__content,
.wp-block-media-text.is-vertically-aligned-top .wp-block-media-text__media {
  -ms-grid-row-align: start;
  align-self: start;
}

.wp-block-media-text .wp-block-media-text__content,
.wp-block-media-text .wp-block-media-text__media,
.wp-block-media-text.is-vertically-aligned-center .wp-block-media-text__content,
.wp-block-media-text.is-vertically-aligned-center .wp-block-media-text__media {
  -ms-grid-row-align: center;
  align-self: center;
}

.wp-block-media-text.is-vertically-aligned-bottom .wp-block-media-text__content,
.wp-block-media-text.is-vertically-aligned-bottom .wp-block-media-text__media {
  -ms-grid-row-align: end;
  align-self: end;
}

.wp-block-media-text .wp-block-media-text__media {
  /*!rtl:begin:ignore*/
  -ms-grid-column: 1;
  grid-column: 1;
  -ms-grid-row: 1;
  grid-row: 1;
  /*!rtl:end:ignore*/
  margin: 0;
}

.wp-block-media-text .wp-block-media-text__content {
  direction: ltr;
  /*!rtl:begin:ignore*/
  -ms-grid-column: 2;
  grid-column: 2;
  -ms-grid-row: 1;
  grid-row: 1;
  /*!rtl:end:ignore*/
  padding: 0 8% 0 8%;
  word-break: break-word;
}

.wp-block-media-text.has-media-on-the-right .wp-block-media-text__media {
  /*!rtl:begin:ignore*/
  -ms-grid-column: 2;
  grid-column: 2;
  -ms-grid-row: 1;
  grid-row: 1;
  /*!rtl:end:ignore*/
}

.wp-block-media-text.has-media-on-the-right .wp-block-media-text__content {
  /*!rtl:begin:ignore*/
  -ms-grid-column: 1;
  grid-column: 1;
  -ms-grid-row: 1;
  grid-row: 1;
  /*!rtl:end:ignore*/
}

.wp-block-media-text > figure > img,
.wp-block-media-text > figure > video {
  max-width: unset;
  width: 100%;
  vertical-align: middle;
}

.wp-block-media-text.is-image-fill figure.wp-block-media-text__media {
  height: 100%;
  min-height: 250px;
  background-size: cover;
}

.wp-block-media-text.is-image-fill figure.wp-block-media-text__media > img {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

/*
* Here we here not able to use a mobile first CSS approach.
* Custom widths are set using inline styles, and on mobile,
* we need 100% width, so we use important to overwrite the inline style.
* If the style were set on mobile first, on desktop styles,
* we would have no way of setting the style again to the inline style.
*/
@media (max-width: 600px) {
  .wp-block-media-text.is-stacked-on-mobile {
    -ms-grid-columns: 100% !important;
    grid-template-columns: 100% !important;
  }

  .wp-block-media-text.is-stacked-on-mobile .wp-block-media-text__media {
    -ms-grid-column: 1;
    grid-column: 1;
    -ms-grid-row: 1;
    grid-row: 1;
  }

  .wp-block-media-text.is-stacked-on-mobile .wp-block-media-text__content {
    -ms-grid-column: 1;
    grid-column: 1;
    -ms-grid-row: 2;
    grid-row: 2;
  }

  .wp-block-media-text.is-stacked-on-mobile.has-media-on-the-right .wp-block-media-text__media {
    -ms-grid-column: 1;
    grid-column: 1;
    -ms-grid-row: 2;
    grid-row: 2;
  }

  .wp-block-media-text.is-stacked-on-mobile.has-media-on-the-right .wp-block-media-text__content {
    -ms-grid-column: 1;
    grid-column: 1;
    -ms-grid-row: 1;
    grid-row: 1;
  }
}
/*
* Frontend: reset the default list styles
*/
.wp-block-navigation > ul {
  display: block;
  list-style: none;
  margin: 0;
  padding-left: 0;
}

@media (min-width: 600px) {
  .wp-block-navigation > ul {
    display: flex;
    flex-wrap: wrap;
  }
}
.wp-block-navigation > ul ul {
  list-style: none;
  padding-left: 0;
  margin-top: 0;
  margin-left: 0;
}

.wp-block-navigation > ul ul li {
  margin: 0;
}

/*
* Frontend: styles for submenu flyout
*/
.wp-block-navigation > ul li {
  z-index: 1;
}

.wp-block-navigation > ul li:hover, .wp-block-navigation > ul li:focus-within {
  cursor: pointer;
  z-index: 99999;
}

.wp-block-navigation > ul li:hover > ul,
.wp-block-navigation > ul li:focus-within > ul,
.wp-block-navigation > ul li ul:hover,
.wp-block-navigation > ul li ul:focus {
  visibility: visible;
  opacity: 1;
  display: flex;
  flex-direction: column;
}

.wp-block-navigation > ul > li ul {
  position: absolute;
  left: 0;
  top: 100%;
  min-width: 200px;
  max-width: 200px;
  opacity: 0;
  transition: opacity 0.1s linear;
  visibility: hidden;
}

/*
* Styles shared between editor and frontend
*/
.wp-block-navigation,
.wp-block-navigation .block-editor-block-list__layout {
  display: flex;
  flex-wrap: wrap;
}

.wp-block-navigation .block-editor-block-list__layout .block-editor-block-list__layout {
  width: 200px;
}

.wp-block-navigation .block-editor-inner-blocks > .block-editor-block-list__layout > .wp-block {
  margin: 0;
  width: auto;
}

.wp-block-navigation,
.wp-block-navigation > .wp-block-navigation__container {
  align-items: center;
  width: 100%;
}

.wp-block-navigation > .wp-block-navigation-link,
.wp-block-navigation > .wp-block-navigation__container > .wp-block-navigation-link {
  display: flex;
  margin-top: 0;
  margin-bottom: 0;
}

.wp-block-navigation .wp-block-navigation-link {
  position: relative;
  margin: 0;
  min-height: 56px;
  display: flex;
  line-height: 1.4;
}

.wp-block-navigation .wp-block-navigation-link .wp-block,
.wp-block-navigation .wp-block-navigation-link .wp-block-navigation-link {
  min-height: auto;
  padding: 0;
}

.wp-block-navigation .wp-block-navigation-link .wp-block .wp-block-navigation-link {
  margin: 0;
}

.wp-block-navigation .wp-block-navigation-link > .block-editor-inner-blocks {
  display: none;
}

.wp-block-navigation .wp-block-navigation-link.has-child > .wp-block-navigation__container,
.wp-block-navigation .wp-block-navigation-link.is-editing.has-child > .block-editor-inner-blocks {
  display: flex;
  border: 1px solid rgba(0, 0, 0, 0.15);
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 0;
}

.wp-block-navigation .wp-block-navigation-link.has-child > .wp-block-navigation__container .block-editor-inner-blocks,
.wp-block-navigation .wp-block-navigation-link.has-child > .wp-block-navigation__container .wp-block-navigation__container,
.wp-block-navigation .wp-block-navigation-link.is-editing.has-child > .block-editor-inner-blocks .block-editor-inner-blocks,
.wp-block-navigation .wp-block-navigation-link.is-editing.has-child > .block-editor-inner-blocks .wp-block-navigation__container {
  left: 100%;
  top: -1px;
}

.wp-block-navigation .wp-block-navigation-link .block-editor-inner-blocks,
.wp-block-navigation .wp-block-navigation-link .wp-block-navigation__container {
  background-color: inherit;
  color: inherit;
}

.wp-block-navigation .wp-block-navigation-link .wp-block-navigation-link__content {
  display: flex;
  align-items: center;
  width: max-content;
  padding: 6px 16px;
}

.wp-block-navigation .wp-block-navigation-link .wp-block-navigation-link:first-child:not(:only-child) .wp-block-navigation-link__content {
  padding-top: 8px;
}

.wp-block-navigation .wp-block-navigation-link .wp-block-navigation-link:last-child .wp-block-navigation-link__content {
  padding-bottom: 8px;
}

.wp-block-navigation .wp-block-navigation-link.has-child .wp-block-navigation-link__content {
  min-width: 100%;
  padding-right: 32px;
  position: relative;
}

.wp-block-navigation .wp-block-navigation-link .wp-block-navigation-link__submenu-icon {
  position: absolute;
  right: 16px;
}

.wp-block-navigation .wp-block-navigation-link .wp-block-navigation-link__submenu-icon svg {
  fill: currentColor;
}

.wp-block-navigation .wp-block-navigation-link .wp-block-navigation-link svg {
  transform: rotate(0);
}

.wp-block-navigation .wp-block-navigation-link.has-text-color .wp-block-navigation-link__content {
  color: inherit;
}

.wp-block-navigation .wp-block-navigation-link:not(.has-text-color) > .block-editor-inner-blocks,
.wp-block-navigation .wp-block-navigation-link:not(.has-text-color) > .wp-block-navigation__container,
.wp-block-navigation.is-style-light .wp-block-navigation-link:not(.has-text-color) > .block-editor-inner-blocks,
.wp-block-navigation.is-style-light .wp-block-navigation-link:not(.has-text-color) > .wp-block-navigation__container {
  color: #111;
}

.wp-block-navigation .wp-block-navigation-link:not(.has-background) > .block-editor-inner-blocks,
.wp-block-navigation .wp-block-navigation-link:not(.has-background) > .wp-block-navigation__container,
.wp-block-navigation.is-style-light .wp-block-navigation-link:not(.has-background) > .block-editor-inner-blocks,
.wp-block-navigation.is-style-light .wp-block-navigation-link:not(.has-background) > .wp-block-navigation__container {
  background-color: #fff;
}

.wp-block-navigation.is-style-dark .wp-block-navigation-link:not(.has-text-color) > .block-editor-inner-blocks,
.wp-block-navigation.is-style-dark .wp-block-navigation-link:not(.has-text-color) > .wp-block-navigation__container {
  color: #fff;
}

.wp-block-navigation.is-style-dark .wp-block-navigation-link:not(.has-background) > .block-editor-inner-blocks,
.wp-block-navigation.is-style-dark .wp-block-navigation-link:not(.has-background) > .wp-block-navigation__container {
  background-color: #333;
}

/*
* Frontend: non-shared styles & overrides
*/
.wp-block-navigation .wp-block-navigation-link.has-child > .wp-block-navigation__container {
  display: flex;
  flex-direction: column;
  padding: 0;
}

/*
* TODO: organize/untangle styles below this line
*/
.wp-block-navigation > ul > li > a {
  display: flex;
  align-items: center;
}

.wp-block-navigation > ul > li:first-of-type > a {
  padding-left: 0;
}

.wp-block-navigation > ul > li:last-of-type > a {
  padding-right: 0;
}

.wp-block-navigation.items-justified-left > ul {
  justify-content: flex-start;
}

.wp-block-navigation.items-justified-center > ul {
  justify-content: center;
}

.wp-block-navigation.items-justified-right > ul {
  justify-content: flex-end;
}

.is-small-text {
  font-size: 14px;
}

.is-regular-text {
  font-size: 16px;
}

.is-large-text {
  font-size: 36px;
}

.is-larger-text {
  font-size: 48px;
}

.has-drop-cap:not(:focus)::first-letter {
  float: left;
  font-size: 8.4em;
  line-height: 0.68;
  font-weight: 100;
  margin: 0.05em 0.1em 0 0;
  text-transform: uppercase;
  font-style: normal;
}

p.has-background {
  padding: 20px 30px;
}

p.has-text-color a {
  color: inherit;
}

.wp-block-pullquote {
  padding: 3em 0;
  margin-left: 0;
  margin-right: 0;
  text-align: center;
}

.wp-block-pullquote.alignleft, .wp-block-pullquote.alignright {
  max-width: 290px;
}

.wp-block-pullquote.alignleft p, .wp-block-pullquote.alignright p {
  font-size: 20px;
}

.wp-block-pullquote p {
  font-size: 28px;
  line-height: 1.6;
}

.wp-block-pullquote cite,
.wp-block-pullquote footer {
  position: relative;
}

.wp-block-pullquote .has-text-color a {
  color: inherit;
}

.wp-block-pullquote:not(.is-style-solid-color) {
  background: none;
}

.wp-block-pullquote.is-style-solid-color {
  border: none;
}

.wp-block-pullquote.is-style-solid-color blockquote {
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  max-width: 60%;
}

.wp-block-pullquote.is-style-solid-color blockquote p {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 32px;
}

.wp-block-pullquote.is-style-solid-color blockquote cite {
  text-transform: none;
  font-style: normal;
}

.wp-block-pullquote cite {
  color: inherit;
}

.wp-block-quote.is-style-large, .wp-block-quote.is-large {
  margin: 0 0 16px;
  padding: 0 1em;
}

.wp-block-quote.is-style-large p, .wp-block-quote.is-large p {
  font-size: 24px;
  font-style: italic;
  line-height: 1.6;
}

.wp-block-quote.is-style-large cite,
.wp-block-quote.is-style-large footer, .wp-block-quote.is-large cite,
.wp-block-quote.is-large footer {
  font-size: 18px;
  text-align: right;
}

.wp-block-rss.alignleft {
  /*rtl:ignore*/
  margin-right: 2em;
}

.wp-block-rss.alignright {
  /*rtl:ignore*/
  margin-left: 2em;
}

.wp-block-rss.is-grid {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  list-style: none;
}

.wp-block-rss.is-grid li {
  margin: 0 16px 16px 0;
  width: 100%;
}

@media (min-width: 600px) {
  .wp-block-rss.columns-2 li {
    width: calc(50% - 16px);
  }

  .wp-block-rss.columns-3 li {
    width: calc(33.3333333333% - 16px);
  }

  .wp-block-rss.columns-4 li {
    width: calc(25% - 16px);
  }

  .wp-block-rss.columns-5 li {
    width: calc(20% - 16px);
  }

  .wp-block-rss.columns-6 li {
    width: calc(16.6666666667% - 16px);
  }
}
.wp-block-rss__item-publish-date,
.wp-block-rss__item-author {
  display: block;
  color: #6c7781;
  font-size: 13px;
}

.wp-block-search {
  display: flex;
  flex-wrap: wrap;
}

.wp-block-search .wp-block-search__label {
  width: 100%;
}

.wp-block-search .wp-block-search__input {
  flex-grow: 1;
  max-width: 360px;
}

.wp-block-search .wp-block-search__button {
  margin-left: 10px;
}

.wp-block-separator.is-style-wide {
  border-bottom-width: 1px;
}

.wp-block-separator.is-style-dots {
  background: none !important;
  border: none;
  text-align: center;
  max-width: none;
  line-height: 1;
  height: auto;
}

.wp-block-separator.is-style-dots::before {
  content: "···";
  color: currentColor;
  font-size: 20px;
  letter-spacing: 2em;
  padding-left: 2em;
  font-family: serif;
}

.wp-block-social-links {
  display: flex;
  justify-content: flex-start;
  padding-left: 0;
  padding-right: 0;
  margin-left: 0;
}

.wp-block-social-links .wp-social-link a,
.wp-block-social-links .wp-social-link a:hover {
  text-decoration: none;
  border-bottom: 0;
  box-shadow: none;
}

.wp-social-link {
  display: block;
  width: 36px;
  height: 36px;
  border-radius: 36px;
  margin-right: 8px;
  transition: transform 0.1s ease;
}

@media (prefers-reduced-motion: reduce) {
  .wp-social-link {
    transition-duration: 0s;
  }
}
.wp-social-link a {
  padding: 6px;
  display: block;
  line-height: 0;
  transition: transform 0.1s ease;
}

.wp-social-link a,
.wp-social-link a:hover,
.wp-social-link a:active,
.wp-social-link a:visited,
.wp-social-link svg {
  color: currentColor;
  fill: currentColor;
}

.wp-social-link:hover {
  transform: scale(1.1);
}

.wp-block-social-links.aligncenter {
  justify-content: center;
  display: flex;
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link {
  background-color: #f0f0f0;
  color: #444;
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-amazon {
  background-color: #f90;
  color: #fff;
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-bandcamp {
  background-color: #1ea0c3;
  color: #fff;
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-behance {
  background-color: #0757fe;
  color: #fff;
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-codepen {
  background-color: #1e1f26;
  color: #fff;
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-deviantart {
  background-color: #02e49b;
  color: #fff;
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-dribbble {
  background-color: #e94c89;
  color: #fff;
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-dropbox {
  background-color: #4280ff;
  color: #fff;
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-etsy {
  background-color: #f45800;
  color: #fff;
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-facebook {
  background-color: #1977f2;
  color: #fff;
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-fivehundredpx {
  background-color: #000;
  color: #fff;
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-flickr {
  background-color: #0461dd;
  color: #fff;
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-foursquare {
  background-color: #e65678;
  color: #fff;
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-github {
  background-color: #24292d;
  color: #fff;
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-goodreads {
  background-color: #eceadd;
  color: #382110;
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-google {
  background-color: #ea4434;
  color: #fff;
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-instagram {
  background-color: #f00075;
  color: #fff;
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-lastfm {
  background-color: #e21b24;
  color: #fff;
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-linkedin {
  background-color: #0577b5;
  color: #fff;
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-mastodon {
  background-color: #3288d4;
  color: #fff;
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-medium {
  background-color: #02ab6c;
  color: #fff;
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-meetup {
  background-color: #f6405f;
  color: #fff;
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-pinterest {
  background-color: #e60122;
  color: #fff;
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-pocket {
  background-color: #ef4155;
  color: #fff;
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-reddit {
  background-color: #fe4500;
  color: #fff;
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-skype {
  background-color: #0478d7;
  color: #fff;
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-snapchat {
  background-color: #fefc00;
  color: #fff;
  stroke: #000;
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-soundcloud {
  background-color: #ff5600;
  color: #fff;
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-spotify {
  background-color: #1bd760;
  color: #fff;
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-tumblr {
  background-color: #011835;
  color: #fff;
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-twitch {
  background-color: #6440a4;
  color: #fff;
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-twitter {
  background-color: #21a1f3;
  color: #fff;
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-vimeo {
  background-color: #1eb7ea;
  color: #fff;
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-vk {
  background-color: #4680c2;
  color: #fff;
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-wordpress {
  background-color: #3499cd;
  color: #fff;
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-yelp {
  background-color: #d32422;
  color: #fff;
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-youtube {
  background-color: #ff0100;
  color: #fff;
}

.wp-block-social-links.is-style-logos-only .wp-social-link {
  background: none;
  padding: 4px;
}

.wp-block-social-links.is-style-logos-only .wp-social-link svg {
  width: 28px;
  height: 28px;
}

.wp-block-social-links.is-style-logos-only .wp-social-link-amazon {
  color: #f90;
}

.wp-block-social-links.is-style-logos-only .wp-social-link-bandcamp {
  color: #1ea0c3;
}

.wp-block-social-links.is-style-logos-only .wp-social-link-behance {
  color: #0757fe;
}

.wp-block-social-links.is-style-logos-only .wp-social-link-codepen {
  color: #1e1f26;
}

.wp-block-social-links.is-style-logos-only .wp-social-link-deviantart {
  color: #02e49b;
}

.wp-block-social-links.is-style-logos-only .wp-social-link-dribbble {
  color: #e94c89;
}

.wp-block-social-links.is-style-logos-only .wp-social-link-dropbox {
  color: #4280ff;
}

.wp-block-social-links.is-style-logos-only .wp-social-link-etsy {
  color: #f45800;
}

.wp-block-social-links.is-style-logos-only .wp-social-link-facebook {
  color: #1977f2;
}

.wp-block-social-links.is-style-logos-only .wp-social-link-fivehundredpx {
  color: #000;
}

.wp-block-social-links.is-style-logos-only .wp-social-link-flickr {
  color: #0461dd;
}

.wp-block-social-links.is-style-logos-only .wp-social-link-foursquare {
  color: #e65678;
}

.wp-block-social-links.is-style-logos-only .wp-social-link-github {
  color: #24292d;
}

.wp-block-social-links.is-style-logos-only .wp-social-link-goodreads {
  color: #382110;
}

.wp-block-social-links.is-style-logos-only .wp-social-link-google {
  color: #ea4434;
}

.wp-block-social-links.is-style-logos-only .wp-social-link-instagram {
  color: #f00075;
}

.wp-block-social-links.is-style-logos-only .wp-social-link-lastfm {
  color: #e21b24;
}

.wp-block-social-links.is-style-logos-only .wp-social-link-linkedin {
  color: #0577b5;
}

.wp-block-social-links.is-style-logos-only .wp-social-link-mastodon {
  color: #3288d4;
}

.wp-block-social-links.is-style-logos-only .wp-social-link-medium {
  color: #02ab6c;
}

.wp-block-social-links.is-style-logos-only .wp-social-link-meetup {
  color: #f6405f;
}

.wp-block-social-links.is-style-logos-only .wp-social-link-pinterest {
  color: #e60122;
}

.wp-block-social-links.is-style-logos-only .wp-social-link-pocket {
  color: #ef4155;
}

.wp-block-social-links.is-style-logos-only .wp-social-link-reddit {
  color: #fe4500;
}

.wp-block-social-links.is-style-logos-only .wp-social-link-skype {
  color: #0478d7;
}

.wp-block-social-links.is-style-logos-only .wp-social-link-snapchat {
  color: #fff;
  stroke: #000;
}

.wp-block-social-links.is-style-logos-only .wp-social-link-soundcloud {
  color: #ff5600;
}

.wp-block-social-links.is-style-logos-only .wp-social-link-spotify {
  color: #1bd760;
}

.wp-block-social-links.is-style-logos-only .wp-social-link-tumblr {
  color: #011835;
}

.wp-block-social-links.is-style-logos-only .wp-social-link-twitch {
  color: #6440a4;
}

.wp-block-social-links.is-style-logos-only .wp-social-link-twitter {
  color: #21a1f3;
}

.wp-block-social-links.is-style-logos-only .wp-social-link-vimeo {
  color: #1eb7ea;
}

.wp-block-social-links.is-style-logos-only .wp-social-link-vk {
  color: #4680c2;
}

.wp-block-social-links.is-style-logos-only .wp-social-link-wordpress {
  color: #3499cd;
}

.wp-block-social-links.is-style-logos-only .wp-social-link-yelp {
  background-color: #d32422;
  color: #fff;
}

.wp-block-social-links.is-style-logos-only .wp-social-link-youtube {
  color: #ff0100;
}

.wp-block-social-links.is-style-pill-shape .wp-social-link {
  width: auto;
}

.wp-block-social-links.is-style-pill-shape .wp-social-link a {
  padding-left: 16px;
  padding-right: 16px;
}

.wp-block-spacer {
  clear: both;
}

p.wp-block-subhead {
  font-size: 1.1em;
  font-style: italic;
  opacity: 0.75;
}

.wp-block-table {
  overflow-x: auto;
}

.wp-block-table table {
  width: 100%;
}

.wp-block-table .has-fixed-layout {
  table-layout: fixed;
  width: 100%;
}

.wp-block-table .has-fixed-layout td,
.wp-block-table .has-fixed-layout th {
  word-break: break-word;
}

.wp-block-table.alignleft, .wp-block-table.aligncenter, .wp-block-table.alignright {
  display: table;
  width: auto;
}

.wp-block-table.alignleft td,
.wp-block-table.alignleft th, .wp-block-table.aligncenter td,
.wp-block-table.aligncenter th, .wp-block-table.alignright td,
.wp-block-table.alignright th {
  word-break: break-word;
}

.wp-block-table .has-subtle-light-gray-background-color {
  background-color: #f3f4f5;
}

.wp-block-table .has-subtle-pale-green-background-color {
  background-color: #e9fbe5;
}

.wp-block-table .has-subtle-pale-blue-background-color {
  background-color: #e7f5fe;
}

.wp-block-table .has-subtle-pale-pink-background-color {
  background-color: #fcf0ef;
}

.wp-block-table.is-style-stripes {
  border-spacing: 0;
  border-collapse: inherit;
  background-color: transparent;
  border-bottom: 1px solid #f3f4f5;
}

.wp-block-table.is-style-stripes tbody tr:nth-child(odd) {
  background-color: #f3f4f5;
}

.wp-block-table.is-style-stripes.has-subtle-light-gray-background-color tbody tr:nth-child(odd) {
  background-color: #f3f4f5;
}

.wp-block-table.is-style-stripes.has-subtle-pale-green-background-color tbody tr:nth-child(odd) {
  background-color: #e9fbe5;
}

.wp-block-table.is-style-stripes.has-subtle-pale-blue-background-color tbody tr:nth-child(odd) {
  background-color: #e7f5fe;
}

.wp-block-table.is-style-stripes.has-subtle-pale-pink-background-color tbody tr:nth-child(odd) {
  background-color: #fcf0ef;
}

.wp-block-table.is-style-stripes th,
.wp-block-table.is-style-stripes td {
  border-color: transparent;
}

.wp-block-text-columns {
  display: flex;
}

.wp-block-text-columns.aligncenter {
  display: flex;
}

.wp-block-text-columns .wp-block-column {
  margin: 0 16px;
  padding: 0;
}

.wp-block-text-columns .wp-block-column:first-child {
  margin-left: 0;
}

.wp-block-text-columns .wp-block-column:last-child {
  margin-right: 0;
}

.wp-block-text-columns.columns-2 .wp-block-column {
  width: 50%;
}

.wp-block-text-columns.columns-3 .wp-block-column {
  width: 33.3333333333%;
}

.wp-block-text-columns.columns-4 .wp-block-column {
  width: 25%;
}

.wp-block-video {
  margin-left: 0;
  margin-right: 0;
}

.wp-block-video video {
  max-width: 100%;
}

@supports (position: -webkit-sticky) or (position: sticky) {
  .wp-block-video [poster] {
    object-fit: cover;
  }
}
.wp-block-video.aligncenter {
  text-align: center;
}

.wp-block-video figcaption {
  margin-top: 0.5em;
  margin-bottom: 1em;
}

#postdivrich {
  display: none;
}

.has-pr-primary-color {
  color: #2d81b6;
}

.has-pr-secondary-color {
  color: #0072B9;
}

.has-pr-tertiary-color {
  color: #608DBF;
}

.has-pr-gray-1-color {
  color: #252525;
}

.has-pr-gray-2-color {
  color: #777;
}

.has-pr-gray-3-color {
  color: #C2C2C2;
}

.has-pr-gray-4-color {
  color: #F0F0F0;
}

.has-pr-white-color {
  color: #FFF;
}

.has-pr-prevor-color {
  color: #2d81b6;
}

.has-pr-diphoterine-color {
  color: #F29400;
}

.has-pr-hexafluorine-color {
  color: #9D62A0;
}

.has-pr-trivorex-color {
  color: #5B2E7C;
}

.has-pr-polycaptor-color {
  color: #218F7D;
}

.has-pr-levert-color {
  color: #004425;
}

.has-pr-acicaptal-color {
  color: #E75294;
}

.has-pr-basicaptal-color {
  color: #009EE0;
}

.has-pr-colopik-color {
  color: #38D430;
}

.has-pr-safurex-color {
  color: #8C7D70;
}

.has-pr-primary-background-color {
  background-color: #2d81b6;
}

.has-pr-secondary-background-color {
  background-color: #0072B9;
}

.has-pr-tertiary-background-color {
  background-color: #608DBF;
}

.has-pr-gray-1-background-color {
  background-color: #252525;
}

.has-pr-gray-2-background-color {
  background-color: #777;
}

.has-pr-gray-3-background-color {
  background-color: #C2C2C2;
}

.has-pr-gray-4-background-color {
  background-color: #F0F0F0;
}

.has-pr-white-background-color {
  background-color: #FFF;
}

.has-pr-prevor-background-color {
  background-color: #2d81b6;
}

.has-pr-diphoterine-background-color {
  background-color: #F29400;
}

.has-pr-hexafluorine-background-color {
  background-color: #9D62A0;
}

.has-pr-trivorex-background-color {
  background-color: #5B2E7C;
}

.has-pr-polycaptor-background-color {
  background-color: #218F7D;
}

.has-pr-levert-background-color {
  background-color: #004425;
}

.has-pr-acicaptal-background-color {
  background-color: #E75294;
}

.has-pr-basicaptal-background-color {
  background-color: #009EE0;
}

.has-pr-colopik-background-color {
  background-color: #38D430;
}

.has-pr-safurex-background-color {
  background-color: #8C7D70;
}

.has-small-font-size {
  font-size: 13px;
}

.has-regular-font-size,
.has-normal-font-size {
  font-size: 16px;
}

.has-medium-font-size {
  font-size: 20px;
}

.has-large-font-size {
  font-size: 36px;
}

.has-larger-font-size,
.has-huge-font-size {
  font-size: 42px;
}

.has-text-align-center {
  text-align: center;
}

.has-text-align-left {
  /*rtl:ignore*/
  text-align: left;
}

.has-text-align-right {
  /*rtl:ignore*/
  text-align: right;
}

.wpcf7-form {
  /* Base for label styling */
  /* checkbox aspect */
  /* checked mark aspect */
  /* checked mark aspect changes */
  /* disabled checkbox */
  /* accessibility */
}
.wpcf7-form .label-select, .wpcf7-form input[type=text], .wpcf7-form input[type=email], .wpcf7-form input[type=tel], .wpcf7-form input[type=submit], .wpcf7-form select, .wpcf7-form textarea {
  width: 100%;
}
.wpcf7-form span.wpcf7-list-item {
  margin: 0;
}
.wpcf7-form .form-group {
  position: relative;
  margin-top: 15px;
  margin-bottom: 15px;
}
.wpcf7-form .form-label {
  position: absolute;
  left: auto;
  top: 10px;
  color: #999;
  background-color: transparent;
  z-index: 10;
  transition: transform 150ms ease-out, font-size 150ms ease-out;
  cursor: text;
  padding-left: 10px;
}
.wpcf7-form .focused .form-label {
  transform: translateY(-125%);
  font-size: 0.75em;
  cursor: default;
  color: #2d81b6;
  padding-left: 0;
}
.wpcf7-form .form-input {
  position: relative;
  padding: 13px 0 5px 10px;
  width: 100%;
  outline: 0;
  border: 0;
  border-radius: 0;
  box-shadow: 0 1px 0 0 #e5e5e5;
  transition: box-shadow 150ms ease-out;
  min-width: auto;
  background-color: transparent;
  -webkit-appearance: none;
}
.wpcf7-form .form-input:focus {
  box-shadow: 0 2px 0 0 #2d81b6;
}
.wpcf7-form .wpcf7-textarea {
  border: 1px solid #e5e5e5;
}
.wpcf7-form .btn-submit {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #2d81b6;
  border-radius: 0;
}
.wpcf7-form .btn-submit input {
  border: none;
  background-color: transparent;
  color: #fff;
}
.wpcf7-form .btn-submit span {
  position: absolute;
}
.wpcf7-form .btn-submit:hover:not(.disabled) {
  background-color: #0072B9;
}
.wpcf7-form [type=checkbox]:not(:checked),
.wpcf7-form [type=checkbox]:checked {
  position: absolute;
  left: -9999px;
}
.wpcf7-form [type=checkbox]:not(:checked) + span,
.wpcf7-form [type=checkbox]:checked + span {
  position: relative;
  padding-left: 1.95em;
  cursor: pointer;
}
.wpcf7-form [type=checkbox]:not(:checked) + span:before,
.wpcf7-form [type=checkbox]:checked + span:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 1.25em;
  height: 1.25em;
  border: 2px solid #ccc;
  background: #fff;
  border-radius: 4px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
}
.wpcf7-form [type=checkbox]:not(:checked) + span:after,
.wpcf7-form [type=checkbox]:checked + span:after {
  content: "✓ ";
  position: absolute;
  top: 0.15em;
  left: 0.22em;
  font-size: 1.3em;
  line-height: 0.8;
  color: #2d81b6;
  transition: all 0.2s;
  font-family: "Lucida Sans Unicode", "Arial Unicode MS", Arial;
}
.wpcf7-form [type=checkbox]:not(:checked) + span:after {
  opacity: 0;
  transform: scale(0);
}
.wpcf7-form [type=checkbox]:checked + span:after {
  opacity: 1;
  transform: scale(1);
}
.wpcf7-form [type=checkbox]:disabled:not(:checked) + span:before,
.wpcf7-form [type=checkbox]:disabled:checked + span:before {
  box-shadow: none;
  border-color: #bbb;
  background-color: #ddd;
}
.wpcf7-form [type=checkbox]:disabled:checked + span:after {
  color: #999;
}
.wpcf7-form [type=checkbox]:disabled + span {
  color: #aaa;
}
.wpcf7-form [type=checkbox]:checked:focus + span:before,
.wpcf7-form [type=checkbox]:not(:checked):focus + span:before {
  border: 2px dotted blue;
}

.label-select, .pp-country {
  margin: 8px 0 15px 0;
  position: relative;
  min-width: 15em;
  /* Remove IE arrow */
}
.label-select select, .pp-country select {
  border: none;
  box-shadow: 0 1px 0 0 #e5e5e5;
  background-color: transparent;
  color: #999;
  padding: 12px 0px 5px 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  outline: 0;
  border: 0 !important;
  background-image: none;
  cursor: pointer;
  width: 100%;
}
.label-select select::-ms-expand, .pp-country select::-ms-expand {
  display: none;
}
.label-select::after, .pp-country::after {
  content: "▼";
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  padding: 0 1em;
  color: #999;
  cursor: pointer;
  pointer-events: none;
  -webkit-transition: 0.25s all ease;
  -o-transition: 0.25s all ease;
  transition: 0.25s all ease;
}
.label-select:hover::after, .pp-country:hover::after {
  color: #0072B9;
}

.bg-pr-primary {
  background: #2d81b6;
}

.bg-pr-secondary {
  background: #0072B9;
}

.bg-pr-tertiary {
  background: #608DBF;
}

.bg-prevor {
  background: #2d81b6;
}

.bg-prevor-light {
  background: #E1EBF7;
}

.bg-diphoterine {
  background: #F29400;
}

.bg-diphoterine-light {
  background: #FEEFDA;
}

.bg-hexafluorine {
  background: #9D62A0;
}

.bg-hexafluorine-light {
  background: #FDE0FF;
}

.bg-trivorex {
  background: #5B2E7C;
}

.bg-polycaptor {
  background: #218F7D;
}

.bg-polycaptor-light {
  background: #DEEEED;
}

.bg-levert {
  background: #004425;
}

.bg-acicaptal {
  background: #E75294;
}

.bg-basicaptal {
  background: #009EE0;
}

.bg-colopik {
  background: #38D430;
}

.bg-safurex {
  background: #8C7D70;
}

.bg-axoc {
  background: #BE1621;
}

.bg-axoc-light {
  background: #F5DCDF;
}

.bg-gray-1 {
  background: #1C1C1C;
}

.bg-gray-2 {
  background: #B8B8B8;
}

.bg-gray-3 {
  background: #DDDCDA;
}

.bg-gray-4 {
  background: #F7F7F7;
}

.border-1 {
  border: solid 1px;
}

.border-2 {
  border: 2px solid;
}

.border-2-bottom {
  border-bottom: 2px solid;
}

.border-3-bottom {
  border-bottom: 3px solid;
}

.border-8 {
  border: 8px solid #00000000;
}

.border-pr-primary {
  border-color: #2d81b6;
}

.border-pr-diphoterine {
  border-color: #F29400;
}

.border-pr-gray-3 {
  border-color: #C2C2C2;
}

.btn-pr {
  transform: skew(-30deg);
  flex-grow: 1;
  color: #FFF;
}

.btn-pr-noskew {
  transform: skew(30deg);
}

.btn-share {
  width: 24px;
  height: 27px;
  fill: #252525;
}

.btn-linkedin {
  width: 54px;
  height: 28px;
}
.btn-linkedin .st0 {
  fill: #2F6296;
}
.btn-linkedin .st1 {
  fill: #FFF;
}

.btn-mail {
  width: 54px;
  height: 28px;
}
.btn-mail .st0 {
  fill: #FFF;
  stroke: #2d81b6;
  stroke-miterlimit: 10;
}
.btn-mail .st1 {
  fill: #2d81b6;
}

.read-more {
  display: flex;
  margin-top: 1rem;
}

.btn-search .btn {
  height: 32px;
}

.carousel-indicators {
  bottom: 0px;
}
.carousel-indicators li {
  background: #C2C2C2;
  height: 0;
  width: 24px;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  padding: 0;
  opacity: 1;
}
.carousel-indicators .active {
  background: #2d81b6;
}
.carousel-indicators-shape {
  transform: skew(-30deg);
  flex-grow: 1;
}
.carousel-control-next, .carousel-control-prev {
  width: auto;
  z-index: 2;
}
.carousel-control-round {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #0072B9;
  padding: 0.5em;
  height: 6em;
}

.files {
  /* Base for label styling */
  /* checkbox aspect */
  /* checked mark aspect */
  /* checked mark aspect changes */
  /* disabled checkbox */
  /* accessibility */
}
.files [type=checkbox]:not(:checked),
.files [type=checkbox]:checked {
  position: absolute;
  left: -9999px;
}
.files [type=checkbox]:not(:checked) + label,
.files [type=checkbox]:checked + label {
  position: relative;
  padding-left: 1.95em;
  cursor: pointer;
}
.files [type=checkbox]:not(:checked) + label:before,
.files [type=checkbox]:checked + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 1.25em;
  height: 1.25em;
  border: 2px solid #ccc;
  background: #fff;
  border-radius: 4px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
}
.files [type=checkbox]:not(:checked) + label:after,
.files [type=checkbox]:checked + label:after {
  content: "✓ ";
  position: absolute;
  top: 0.15em;
  left: 0.22em;
  font-size: 1.3em;
  line-height: 0.8;
  color: #2d81b6;
  transition: all 0.2s;
  font-family: "Lucida Sans Unicode", "Arial Unicode MS", Arial;
}
.files [type=checkbox]:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
}
.files [type=checkbox]:checked + label:after {
  opacity: 1;
  transform: scale(1);
}
.files [type=checkbox]:disabled:not(:checked) + label:before,
.files [type=checkbox]:disabled:checked + label:before {
  box-shadow: none;
  border-color: #bbb;
  background-color: #ddd;
}
.files [type=checkbox]:disabled:checked + label:after {
  color: #999;
}
.files [type=checkbox]:disabled + label {
  color: #aaa;
}
.files [type=checkbox]:checked:focus + label:before,
.files [type=checkbox]:not(:checked):focus + label:before {
  border: 2px dotted blue;
}
.files [type=radio]:checked,
.files [type=radio]:not(:checked) {
  position: absolute;
  left: -9999px;
}
.files [type=radio]:checked + label,
.files [type=radio]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}
.files [type=radio]:checked + label:before,
.files [type=radio]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
}
.files [type=radio]:checked + label:after,
.files [type=radio]:not(:checked) + label:after {
  content: "";
  width: 12px;
  height: 12px;
  background: #2d81b6;
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.files [type=radio]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
.files [type=radio]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
.files .nav-link [type=radio]:checked + label,
.files .nav-link [type=radio]:not(:checked) + label {
  color: inherit;
}

.form-input {
  min-width: 17rem;
}

#SelectionDebutProduit {
  font-size: 13px;
}

.navbar-advantage-nav li {
  font-size: 0.75em;
}

.nav-advantage-item-link {
  color: #252525;
}

.pagination .page-item .page-link {
  color: #2d81b6;
}
.pagination .page-item.active .page-link {
  color: #fff;
  background-color: #2d81b6;
}

.btn-menu {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 16px;
  background-color: transparent;
  color: inherit;
  padding-left: 0;
  padding-right: 0;
  cursor: pointer;
  transition: 0.3s ease;
}
.btn-menu:focus {
  outline: none;
}
.btn-menu__text {
  margin-left: 10px;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1;
}
.btn-menu__bars {
  display: block;
  position: relative;
  width: 22px;
  height: 2px;
  background-color: #2d81b6;
  transition: 0.3s;
}
.btn-menu__bars:before, .btn-menu__bars:after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #2d81b6;
  transition: 0.3s;
}
.btn-menu__bars:before {
  transform: translate(0, -7px);
}
.btn-menu__bars:after {
  transform: translate(0, 7px);
}
.btn-menu.menu-open .btn-menu__bars {
  background-color: transparent;
}
.btn-menu.menu-open .btn-menu__bars:before, .btn-menu.menu-open .btn-menu__bars:after {
  background-color: #fff;
}
.btn-menu.menu-open .btn-menu__bars:before {
  transform: rotate(45deg);
}
.btn-menu.menu-open .btn-menu__bars:after {
  transform: rotate(-45deg);
}

.info-sci-btn {
  height: 32px;
}
.info-sci-img {
  height: 100%;
}

.nav-tabs {
  border-bottom: 3px solid #2d81b6;
}
.nav-tabs .nav-item .nav-link {
  border: none;
  border-radius: 0;
  color: #252525;
  background-color: transparent;
}
.nav-tabs .nav-item .nav-link.active {
  background-color: #2d81b6;
  color: #fff;
}
.nav-tabs .nav-item .nav-link:hover {
  background-color: #0072B9;
  color: #fff;
}

.btn-mobile-sidebar {
  min-width: 46px;
}

table, .table, .table-hover {
  margin-bottom: 1rem;
  border: 1px solid #C2C2C2;
}
table tr th, .table tr th, .table-hover tr th {
  border: 0;
}
table thead, .table thead, .table-hover thead {
  background-color: #2d81b6;
  color: #fff;
}
table thead tr th, .table thead tr th, .table-hover thead tr th {
  font-weight: 400;
  vertical-align: top;
}
table tbody tr:nth-child(odd), .table tbody tr:nth-child(odd), .table-hover tbody tr:nth-child(odd) {
  background-color: #F0F0F0;
}
table tbody tr:nth-child(even), .table tbody tr:nth-child(even), .table-hover tbody tr:nth-child(even) {
  background-color: #fff;
}
table tbody tr:hover, .table tbody tr:hover, .table-hover tbody tr:hover {
  background-color: #C2C2C2;
}
table tbody tr td, .table tbody tr td, .table-hover tbody tr td {
  padding: 0.5em 1.5em;
  border: 0;
}

.text-pr-primary {
  color: #2d81b6;
}

.text-pr-secondary {
  color: #0072B9;
}

.text-pr-tertiary {
  color: #608DBF;
}

.text-prevor {
  color: #2d81b6;
}

.text-prevor-light {
  color: #E1EBF7;
}

.text-diphoterine {
  color: #F29400;
}

.text-diphoterine-light {
  color: #FEEFDA;
}

.text-hexafluorine {
  color: #9D62A0;
}

.text-hexafluorine-light {
  color: #FDE0FF;
}

.text-trivorex {
  color: #5B2E7C;
}

.text-polycaptor {
  color: #218F7D;
}

.text-polycaptor-light {
  color: #DEEEED;
}

.text-levert {
  color: #004425;
}

.text-acicaptal {
  color: #E75294;
}

.text-basicaptal {
  color: #009EE0;
}

.text-colopik {
  color: #38D430;
}

.text-safurex {
  color: #8C7D70;
}

.text-axoc {
  color: #BE1621;
}

.text-axoc-light {
  color: #F5DCDF;
}

.text-gray-1 {
  color: #1C1C1C;
}

.text-gray-2 {
  color: #B8B8B8;
}

.text-gray-3 {
  color: #DDDCDA;
}

.text-gray-4 {
  color: #F7F7F7;
}

.text-default {
  color: #212529;
}

.text-red {
  color: #E42D24;
}

.accordion .card {
  border-radius: 0;
  border: none;
}
.accordion .card-header {
  border-radius: 0;
  border-bottom: 3px solid #2d81b6;
  position: relative;
}
.accordion .card-header .btn {
  color: #2d81b6;
  white-space: normal;
}
.accordion .card-header a {
  font-size: 1rem;
}
.accordion .card-header a:before {
  content: "▼";
  float: right;
  transition: all 0.5s;
  margin-left: 1em;
  transform: rotate(180deg);
}
.accordion .card-header.collapsed a:before {
  transform: rotate(0deg);
}

.blog-author {
  font-size: 0.75rem;
}

#cookie-notice {
  margin-bottom: 3.21rem;
}

@media (max-width: 991.98px) {
  #cookie-notice {
    z-index: 198 !important;
  }

  #customerly-container.customerly-position-right .customerly-launcher {
    right: 15px !important;
  }

  #customerly-container .customerly-launcher {
    bottom: 8px !important;
    width: 32px !important;
    height: 32px !important;
  }

  #customerly-container #customerly-launcher-button-icon, #customerly-container #customerly-launcher-button-icon svg, #customerly-container .customerly-bubble-background, #customerly-container .customerly-bubble-background svg {
    width: 32px !important;
    height: 32px !important;
  }

  #customerly-container #customerly-launcher-button-background {
    width: 28px;
    height: 29px;
  }
}
.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.download-picto {
  bottom: -12px;
  right: -20px;
  width: 77px;
  height: 41px;
}
.download-picto .st0 {
  fill: #2d81b6;
}
.download-picto .st1 {
  fill: #FFF;
}

.banner-download .skew {
  transform: translateX(-50px) skew(-30deg);
}

.hero-image {
  filter: blur(2px);
  -webkit-filter: blur(2px);
  height: 100%;
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.hero-text {
  background-color: rgba(0, 0, 0, 0.2);
  position: relative;
  top: -100%;
  width: 100%;
}

.files-title {
  border-bottom: 1px solid #2d81b6;
  padding-bottom: 0.3em;
  margin-bottom: 1em;
}

.download-icon > a {
  padding: 1em;
  max-width: 220px;
}

.btn-master {
  background-color: #fff;
  border: 1px solid #252525;
  color: #252525;
}
.btn-master:hover, .btn-master:active, .btn-master:focus {
  background-color: #2d81b6;
  border-color: #2d81b6;
  color: #fff;
}

.collapseFilter, .collapseSort {
  min-width: 255px;
  z-index: 2;
}
.collapseFilter .card, .collapseSort .card {
  border-radius: 0;
  border: none;
  border-bottom: 1px solid #fff;
}
.collapseFilter .card-header, .collapseSort .card-header {
  border-radius: 0;
  border: none;
}
.collapseFilter .card-header a, .collapseSort .card-header a {
  font-size: 1rem;
  line-height: 1.11rem;
}
.collapseFilter .card-header a:before, .collapseSort .card-header a:before {
  content: "▼";
  float: right;
  transition: all 0.5s;
  margin-left: 1em;
  transform: rotate(180deg);
}
.collapseFilter .card-header.collapsed a:before, .collapseSort .card-header.collapsed a:before {
  transform: rotate(0deg);
}

.collapseSort input {
  position: absolute;
  top: 0;
  left: 0;
  display: none;
}
.collapseSort label {
  padding-left: 0 !important;
}
.collapseSort label:before, .collapseSort label:after {
  display: none;
}

.youtube {
  background-color: #C2C2C2;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}
.youtube img {
  width: 100%;
  top: -16.84%;
  left: 0;
  opacity: 0.7;
}
.youtube .play-button {
  width: 75px;
  height: 50px;
  background-color: #2d81b6;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.6);
  z-index: 1;
  opacity: 0.9;
}
.youtube .play-button:before {
  content: "";
  border-style: solid;
  border-width: 12px 0 15px 20px;
  border-color: transparent transparent transparent #fff;
}
.youtube img, .youtube .play-button {
  cursor: pointer;
}
.youtube img, .youtube iframe, .youtube .play-button, .youtube .play-button:before {
  position: absolute;
}
.youtube .play-button {
  top: 50%;
  left: 50%;
  transform: skew(-30deg) translate3d(-70%, -50%, 0);
}
.youtube .play-button:before {
  top: 50%;
  left: 50%;
  transform: skew(30deg) translate3d(0%, -50%, 0);
}
.youtube iframe {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

#exit-popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  visibility: hidden;
  z-index: 3000000000;
  opacity: 0;
}

#exit-popup .wrapper {
  position: relative;
  text-align: center;
  width: max-content;
}

#exit-popup_bg {
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.7;
  z-index: 2147483000;
}

#exit-popup_close {
  position: absolute;
  right: 0;
  bottom: 100%;
  width: 20px;
  height: 20px;
  color: #fff;
  cursor: pointer;
  background-color: #2d81b6;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

#exit-popup_close::before {
  content: "×";
  font: 400 26px/1em "Roboto Slab", serif;
  color: #FFF;
}

#exit-popup .exit-content {
  margin: 0 auto;
}

.bloc-image figcaption {
  text-align: center;
  width: 100%;
  padding: 0.3rem 0;
  background: #0072B9;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5em;
}

.fill-white {
  fill: #FFF;
}

.fill-gray-1 {
  fill: #252525;
}

.fill-primary {
  fill: #2d81b6;
}

.fill-secondary {
  fill: #0072B9;
}

.fill-tertiary {
  fill: #608DBF;
}

.firstaid-table .row:first-of-type .col-1 {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding: 1em 0;
}
.firstaid-table .row:first-of-type .vertical-text {
  text-align: left;
  color: initial;
  writing-mode: tb-rl;
  transform: rotate(-180deg);
  max-height: 16em;
}
.firstaid-table .row .col-1 {
  text-align: center;
  color: green;
}
.firstaid-table-true {
  background: url("../img/check.svg") no-repeat center;
}

.footer {
  background: #2d81b6;
}
.footer-skew {
  width: 110%;
  margin-left: -7%;
}
.footer-title {
  font-family: "roboto-black";
  font-size: 16px;
  margin-bottom: 1em;
}
.footer-list {
  font-family: "roboto-light";
}
.footer-list li {
  margin-bottom: 1em;
}
.footer-list a {
  color: #FFF;
}
.footer-list a:hover {
  color: #FFF;
  text-decoration: underline;
}
.footer-contact .footer-list li {
  margin-bottom: 0.5em;
}

.gap {
  transform: translateY(-164px);
}

.header {
  position: sticky;
  z-index: 199;
  top: 0;
}
.header .sticky-show {
  display: none;
}
.header-middle {
  font-family: "roboto-italic";
  padding-left: 0 !important;
}
.header-middle p {
  margin: auto;
  font-size: 0.9rem;
}
.header-search-form {
  position: relative;
  border: 1px solid #252525;
  padding: 0.2em 0.5em;
  margin-right: -1em;
}
.header-search-input, .header-search-label {
  transform: skew(30deg);
}
.header-search-input {
  border: none;
  background: transparent;
}
.header-search-label {
  position: absolute;
  right: 0.5em;
  top: 0;
}
.header-search-label:after {
  display: block;
  content: "";
  background-size: 28px 28px;
  height: 28px;
  width: 28px;
  background: url("../img/svg/search.svg") no-repeat center center/contain;
}
.header-search-btn {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  width: 42px;
  height: 100%;
  background-color: transparent;
}
.header-ico_tel .textwidget {
  transform: skew(-30deg);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #2d81b6;
  min-width: 46px;
  height: 32px;
}
.header-ico_tel .textwidget svg {
  transform: rotate(360deg) skew(30deg);
}
.header-lang {
  width: 52px;
  height: 32px;
}
.header-lang select {
  color: #777;
  font-family: "roboto-light";
  width: 100%;
  transform: skew(30deg);
  background: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  background-image: url("../img/svg/caret-down.svg");
  background-repeat: no-repeat;
  background-position: 80% center;
  padding-left: 0.4rem !important;
}
.header .nav-container {
  z-index: 100;
}
.header .nav-bar {
  font-family: "roboto-condensed";
  font-size: 16px;
  text-align: center;
}
.header .nav-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}
.header .nav-link {
  color: white;
  padding: 0.7em 0;
}
.header .nav-first {
  background-color: #fff;
}
.header .nav-first a {
  color: #0072B9;
}
.header .nav-dropdown {
  margin: 0;
  border: none;
}
.header .nav-dropdown.left-1 {
  left: 13.6px;
}
.header .nav-dropdown.left-2 {
  left: 27.2px;
}
.header .nav-dropdown.left-3 {
  left: 40.8px;
}
.header .nav-dropdown.left-4 {
  left: 54.4px;
}
.header .nav-dropdown.left-5 {
  left: 68px;
}
.header .nav-dropdown.left-6 {
  left: 81.6px;
}
.header .nav-dropdown.left-7 {
  left: 95.2px;
}
.header .nav-dropdown.left-8 {
  left: 108.8px;
}
.header .nav-dropdown.left-9 {
  left: 122.4px;
}
.header .nav-list-group {
  border-radius: 0;
}
.header .nav-list-item {
  border: none;
}
.header .nav-list-item .nav-link {
  text-align: center;
}
.header .nav-dropdown-sub {
  margin: 0;
  border: 0;
  left: 100%;
  top: 0;
}

.link-card .card {
  background-color: #0072B9;
  overflow: hidden;
  border: none;
  border-radius: 0;
}
.link-card .card-header {
  background-color: #0072B9;
  color: white;
}
.link-card .card-header h4 {
  color: white;
}
.link-card .card .triangle {
  background-image: linear-gradient(to right bottom, #2d81b6 0%, #2d81b6 50%, transparent 50%);
  position: absolute;
  width: 45%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.4;
}

#searchModal {
  background-color: rgba(0, 114, 185, 0.8);
}
#searchModal .header-search-input {
  padding: 0.7em 1.5em;
  font-size: 1.335em;
}
#searchModal .header-search-label {
  margin-top: 1em;
  margin-right: 0.5em;
}

.read-more {
  display: flex;
  margin-top: 1rem;
  color: #2d81b6;
}

.search-form {
  position: relative;
  z-index: 20;
}

.search-field {
  background-color: transparent;
  background-image: url(https://api.ning.com:80/files/dr2jDpSXWFhyuoGJ4YR5Fu-PwuqLz70YxRaUcpdKNxf2HI5ECzddyx3NHuPz-MB4ujGS4rEtdtS-aQoFlQrtv*XwVOu*AvC8/searchicon.png);
  background-position: 5px center;
  background-repeat: no-repeat;
  background-size: 24px 24px;
  border: 1px solid #2d81b6;
  cursor: pointer;
  height: 32px;
  margin: 3px 0;
  padding: 0 1.5rem;
  position: relative;
  -webkit-transition: width 400ms ease, background 400ms ease;
  transition: width 400ms ease, background 400ms ease;
  width: 0;
}

.search-field:focus {
  background-color: #fff;
  border: 2px solid black;
  cursor: text;
  outline: 0;
  width: 230px;
}
.search-field:focus + .search-svg {
  left: 90%;
}

.search-submit {
  display: none;
}

input[type=search] {
  -webkit-appearance: textfield;
}

.search-svg {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) rotate(360deg) skew(30deg);
  width: 30px;
  height: 30px;
}

.sidebar-left {
  position: fixed;
  z-index: 1;
  width: 100%;
}
.sidebar-left .widget-gas {
  font-family: "roboto-condensed";
  font-size: 1rem;
}
.sidebar-left .menu-left-container ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
.sidebar-left .menu-left-container ul li a {
  border-bottom: dashed 1px;
  display: block;
  padding: 0.85rem 0;
  margin: 0 1rem;
  color: #fff;
}
.sidebar-left .menu-left-container ul li:last-of-type a {
  border: none;
}

.sidebar-sticky {
  top: 188px;
  margin-bottom: 1.8em;
}

.sidebar-product-img {
  border: 1px solid #C2C2C2;
}

.top-mobile-sidebar p {
  margin: 0;
  font-size: 0.7rem;
}

.bottom-mobile-sidebar {
  border-top: 2px solid #C2C2C2;
}

.skew {
  transform: skew(-30deg);
}

.noskew {
  transform: skew(30deg);
}

/* Skew only on right side */
.skew-right {
  position: relative;
  display: inline-block;
  background: #0072B9;
  z-index: 1;
}

.skew-right:after {
  content: " ";
  position: absolute;
  z-index: -1;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #0072B9;
  transform-origin: bottom left;
  transform: skew(-30deg, 0deg);
}

.skew-left:before {
  content: "";
  transform: skew(-30deg);
  width: 10%;
  height: 100%;
  background: #2d81b6;
  position: absolute;
  transform-origin: top;
}

#sectorsCarousel {
  /* MD */
  /* LG */
}
#sectorsCarousel .sector-item .sector-block:hover .rectangle {
  opacity: 1;
  width: 100%;
}
#sectorsCarousel .sector-item .sector-block:hover .triangle {
  background-image: linear-gradient(to right bottom, #2d81b6 0%, #2d81b6 50%, transparent 50%);
}
#sectorsCarousel .sector-item .sector-block h5 {
  position: absolute;
  color: #fff;
  bottom: 0;
  right: 0;
  padding: 0.2rem 0.5rem;
}
#sectorsCarousel .sector-item .sector-block .triangle {
  background-image: linear-gradient(to right bottom, rgba(45, 129, 182, 0.6) 0%, rgba(45, 129, 182, 0.6) 50%, transparent 50%);
  position: absolute;
  width: 36%;
  height: 100%;
  top: 0;
  left: 0;
  transition: background 600ms ease-out;
  z-index: 2;
}
#sectorsCarousel .sector-item .sector-block .rectangle {
  z-index: 1;
  background-color: rgba(45, 129, 182, 0.4);
  position: absolute;
  width: 0;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  transition: width 300ms ease-out;
}
#sectorsCarousel .carousel-inner {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
}
#sectorsCarousel .carousel-inner .carousel-item {
  position: absolute;
  transform: translate3d(0, 0, 0);
}
#sectorsCarousel .carousel-inner .carousel-item.active {
  position: relative;
}
@media (min-width: 576px) {
  #sectorsCarousel .carousel-inner .carousel-item.active + .carousel-item {
    position: relative;
  }
}
@media (min-width: 768px) {
  #sectorsCarousel .carousel-inner .carousel-item.active + .carousel-item + .carousel-item {
    position: relative;
  }
}
@media (min-width: 991px) {
  #sectorsCarousel .carousel-inner .carousel-item.active + .carousel-item + .carousel-item + .carousel-item {
    position: relative;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  #sectorsCarousel {
    /* Show 4th slide on md if col-md-4*/
  }
  #sectorsCarousel .carousel-inner .active.col-md-4.carousel-item + .carousel-item + .carousel-item + .carousel-item {
    position: absolute;
    top: 0;
    right: -33.3333%;
    /*change this with javascript in the future*/
    z-index: -1;
    display: block;
  }
}
@media (min-width: 576px) and (max-width: 768px) {
  #sectorsCarousel {
    /* Show 3rd slide on sm if col-sm-6*/
  }
  #sectorsCarousel .carousel-inner .active.col-sm-6.carousel-item + .carousel-item + .carousel-item {
    position: absolute;
    top: 0;
    right: -50%;
    /*change this with javascript in the future*/
    z-index: -1;
    display: block;
  }
}
@media (min-width: 576px) {
  #sectorsCarousel {
    /* show 2 items */
    /* left or forward direction */
    /* farthest right hidden item must be also positioned for animations */
    /* right or prev direction */
  }
  #sectorsCarousel .carousel-item {
    margin-right: 0;
  }
  #sectorsCarousel .carousel-inner .active + .carousel-item {
    display: block;
  }
  #sectorsCarousel .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left),
#sectorsCarousel .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left) + .carousel-item {
    transition: none;
  }
  #sectorsCarousel .carousel-inner .carousel-item-next {
    position: relative;
    transform: translate3d(0, 0, 0);
  }
  #sectorsCarousel .active.carousel-item-left + .carousel-item-next.carousel-item-left,
#sectorsCarousel .carousel-item-next.carousel-item-left + .carousel-item,
#sectorsCarousel .carousel-item-next.carousel-item-left + .carousel-item + .carousel-item {
    position: relative;
    transform: translate3d(-100%, 0, 0);
  }
  #sectorsCarousel .carousel-inner .carousel-item-prev.carousel-item-right {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    display: block;
  }
  #sectorsCarousel .active.carousel-item-right + .carousel-item-prev.carousel-item-right,
#sectorsCarousel .carousel-item-prev.carousel-item-right + .carousel-item,
#sectorsCarousel .carousel-item-prev.carousel-item-right + .carousel-item + .carousel-item {
    position: relative;
    transform: translate3d(100%, 0, 0);
    display: block;
  }
}
@media (min-width: 768px) {
  #sectorsCarousel {
    /* show 3rd of 3 item slide */
    /* left or forward direction */
    /* right or prev direction */
  }
  #sectorsCarousel .carousel-inner .active + .carousel-item + .carousel-item {
    display: block;
  }
  #sectorsCarousel .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left) + .carousel-item + .carousel-item {
    transition: none;
  }
  #sectorsCarousel .carousel-inner .carousel-item-next {
    position: relative;
    transform: translate3d(0, 0, 0);
  }
  #sectorsCarousel .carousel-item-next.carousel-item-left + .carousel-item + .carousel-item + .carousel-item {
    position: relative;
    transform: translate3d(-100%, 0, 0);
  }
  #sectorsCarousel .carousel-item-prev.carousel-item-right + .carousel-item + .carousel-item + .carousel-item {
    position: relative;
    transform: translate3d(100%, 0, 0);
    display: block;
  }
}
@media (min-width: 991px) {
  #sectorsCarousel {
    /* show 4th item */
    /* Show 5th slide on lg if col-lg-3 */
    /* left or forward direction */
    /* right or prev direction //t - previous slide direction last item animation fix */
  }
  #sectorsCarousel .carousel-inner .active + .carousel-item + .carousel-item + .carousel-item {
    display: block;
  }
  #sectorsCarousel .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left) + .carousel-item + .carousel-item + .carousel-item {
    transition: none;
  }
  #sectorsCarousel .carousel-inner .active.col-lg-3.carousel-item + .carousel-item + .carousel-item + .carousel-item + .carousel-item {
    position: absolute;
    top: 0;
    right: -25%;
    /*change this with javascript in the future*/
    z-index: -1;
    display: block;
  }
  #sectorsCarousel .carousel-item-next.carousel-item-left + .carousel-item + .carousel-item + .carousel-item + .carousel-item {
    position: relative;
    transform: translate3d(-100%, 0, 0);
  }
  #sectorsCarousel .carousel-item-prev.carousel-item-right + .carousel-item + .carousel-item + .carousel-item + .carousel-item {
    position: relative;
    transform: translate3d(100%, 0, 0);
    display: block;
  }
}

.galeryModal .modal-content {
  border: none;
  border-radius: 0;
}
.galeryModal .close {
  position: absolute;
  z-index: 3;
  right: 0.5em;
  top: 0.3em;
  color: #2d81b6;
}
.galeryModal .card {
  border-radius: 0;
  border: none;
}
.galeryModal .carouselGalery .carousel-item {
  max-height: 80vh;
}
.galeryModal .carouselGalery .carousel-item img {
  max-width: 100%;
  max-height: 100%;
}

.MultiCarousel {
  float: left;
  overflow: hidden;
  padding: 15px;
  width: 100%;
  position: relative;
}
.MultiCarousel .MultiCarousel-inner {
  transition: 1s ease all;
  float: left;
}
.MultiCarousel .MultiCarousel-inner .item {
  float: left;
  padding: 0 7px;
}
.MultiCarousel .MultiCarousel-inner .item > div {
  text-align: center;
  padding: 10px;
  margin: 10px;
  background: #f1f1f1;
  color: #666;
}
.MultiCarousel .carousel-control.over {
  opacity: 0.1;
  cursor: default;
}
.MultiCarousel .carousel-control.over:hover {
  opacity: 0.1;
}

.MultiCarousel .MultiCarousel-inner .item {
  float: left;
  padding: 0 7px;
}

.MultiCarousel .MultiCarousel-inner .item > div {
  text-align: center;
  padding: 10px;
  margin: 10px;
  background: #f1f1f1;
  color: #666;
}

.testi-row:nth-of-type(n+6) {
  display: none;
}
.testi-show {
  margin-top: -5em;
}
.testi-show-block {
  background: white;
  background: linear-gradient(0deg, white 0%, white 70%, rgba(255, 255, 255, 0) 100%);
}
.testi-show-block.bg-none {
  background: none;
}
.testi-show-btn {
  color: #2d81b6;
}
.testi-show-btn:hover {
  color: #0072B9;
}
.testi-show #testi-show-less {
  display: none;
}
.testi-table-title .skew {
  background-color: #2d81b6;
  color: #fff;
}
.testi-table-block-content {
  border-radius: 0.5em;
  color: #0072B9;
  font-size: 0.875em;
  font-family: "roboto-italic";
}
.testi-table-block-content-author {
  color: #608DBF;
  font-size: 0.85em;
  font-family: "roboto";
}

.author {
  font-family: "roboto-italic";
}

.tprod-picto-1 {
  background: url("../img/check-green.svg") no-repeat center;
}

.advantage-1 {
  background-color: #00BF53;
}

.advantage-2 {
  background-color: #2872E3;
}

.advantage-3 {
  background-color: #E42D24;
}

.pie {
  position: relative;
  width: 50em;
  height: 49.8em;
  border-radius: 50%;
  list-style: none;
  overflow: hidden;
}
.pie .slice {
  transform: rotate(0deg) skewY(-50deg);
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 50%;
  transform-origin: 0% 100%;
}
.pie .slice .slice-contents {
  transform: skewY(50deg);
  /* unskew slice contents */
  position: absolute;
  left: -100%;
  width: 200%;
  border-radius: 50%;
}
.pie .slice .slice-contents .img-block {
  overflow: hidden;
}
.pie .slice .slice-contents .img-block .img-intern {
  position: relative;
  width: 110%;
}
.pie .slice:first-child {
  transform: rotate(0deg) skewY(-50deg);
}
.pie .slice:first-child .img-intern {
  left: -25px;
}
.pie .slice:nth-child(2) {
  transform: rotate(40deg) skewY(-50deg);
}
.pie .slice:nth-child(2) .img-intern {
  transform: rotate(-40deg);
  left: -65px;
  top: -15px;
}
.pie .slice:nth-child(3) {
  transform: rotate(80deg) skewY(-50deg);
}
.pie .slice:nth-child(3) .img-intern {
  transform: rotate(-80deg);
  left: -42px;
  top: 10px;
}
.pie .slice:nth-child(4) {
  transform: rotate(120deg) skewY(-50deg);
}
.pie .slice:nth-child(4) .img-intern {
  transform: rotate(-120deg);
  left: -56px;
  top: -24px;
}
.pie .slice:nth-child(5) {
  transform: rotate(160deg) skewY(-50deg);
}
.pie .slice:nth-child(5) .img-intern {
  transform: rotate(-160deg);
  left: -54px;
}
.pie .slice:nth-child(6) {
  transform: rotate(200deg) skewY(-50deg);
}
.pie .slice:nth-child(6) .img-intern {
  transform: rotate(-200deg);
  left: -42px;
  top: -18px;
}
.pie .slice:nth-child(7) {
  transform: rotate(240deg) skewY(-50deg);
}
.pie .slice:nth-child(7) .img-intern {
  transform: rotate(-240deg);
  left: -54px;
}
.pie .slice:nth-child(8) {
  transform: rotate(280deg) skewY(-50deg);
}
.pie .slice:nth-child(8) .img-intern {
  transform: rotate(-280deg);
  left: -42px;
  top: -8px;
}
.pie .slice:nth-child(9) {
  transform: rotate(320deg) skewY(-50deg);
}
.pie .slice:nth-child(9) .img-intern {
  transform: rotate(-320deg);
  left: -79px;
  top: -19px;
}
.pie .img-border {
  width: 16em;
  height: 7em;
  position: absolute;
  left: 51%;
  clip-path: ellipse(23.5em 25em at 1em 25em);
}
.pie .img-block {
  position: absolute;
  left: 51%;
  width: 33%;
  top: 0.7em;
  clip-path: ellipse(23.2em 24.5em at 0em 25em);
}

.dipho-logo {
  border: 10px solid white;
  border-radius: 50%;
  color: white;
}

.dipho-logo-mobile {
  max-width: 360px;
  width: 100%;
}

@media all and (-ms-high-contrast: none) {
  .dipho-logo {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  /* IE10 */
  *::-ms-backdrop, .dipho-logo {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  /* IE11 */
  *::-ms-backdrop, .well_img img {
    flex-shrink: 0;
  }
}
.basic_well {
  width: 105%;
}
.basic_well .title-advantage {
  position: absolute;
  color: #fff;
  padding: 0.5rem 1.5rem;
  max-width: 17em;
  min-width: 12em;
}
.basic_well .title-advantage span {
  display: inline-block;
}
.basic_well .title-advantage:first-of-type {
  left: 42em;
  bottom: 50em;
}
.basic_well .title-advantage:nth-of-type(2) {
  left: 60em;
  bottom: 38em;
}
.basic_well .title-advantage:nth-of-type(3) {
  left: 61em;
  bottom: 16em;
}
.basic_well .title-advantage:nth-of-type(4) {
  left: 51em;
  top: 45em;
}
.basic_well .title-advantage:nth-of-type(5) {
  left: 30em;
  top: 51em;
}
.basic_well .title-advantage:nth-of-type(6) {
  right: 52em;
  top: 45em;
}
.basic_well .title-advantage:nth-of-type(7) {
  right: 61em;
  top: 31em;
}
.basic_well .title-advantage:nth-of-type(8) {
  right: 58em;
  bottom: 35em;
}
.basic_well .title-advantage:nth-of-type(9) {
  right: 40em;
  bottom: 50em;
}

.hover_well {
  width: 72%;
}
.hover_well_mouse {
  width: 50em;
  height: 49.8em;
  border-radius: 50% 0 0 0;
  overflow: hidden;
}
.hover_well_list {
  color: white;
  max-height: 400px;
  height: 400px;
  font-size: 12px;
  line-height: 2.4rem;
  position: relative;
  right: 6px;
}
.hover_well .adv-clickable li {
  width: 100%;
  position: absolute;
  clip-path: polygon(0% 0%, 100% 53%, 100% 47%, 0% 100%);
  transform-origin: right center;
  bottom: -27px;
}
.hover_well .adv-clickable.active li {
  background: #F0F0F0;
  color: #2d81b6;
}
.hover_well .adv-clickable:first-child li {
  transform: rotate(5deg);
  clip-path: polygon(0% 0%, 100% 53%, 100% 50%, 0% 100%);
}
.hover_well .adv-clickable:nth-child(2) li {
  transform: rotate(15deg);
}
.hover_well .adv-clickable:nth-child(3) li {
  transform: rotate(25deg);
}
.hover_well .adv-clickable:nth-child(4) li {
  transform: rotate(35deg);
}
.hover_well .adv-clickable:nth-child(5) li {
  transform: rotate(45deg);
}
.hover_well .adv-clickable:nth-child(6) li {
  transform: rotate(55deg);
}
.hover_well .adv-clickable:nth-child(7) li {
  transform: rotate(65deg);
}
.hover_well .adv-clickable:nth-child(8) li {
  transform: rotate(75deg);
}
.hover_well .adv-clickable:nth-child(9) li {
  transform: rotate(85deg);
}
.hover_well .well_content, .hover_well .well_img {
  display: flex;
  color: #fff;
}
.hover_well .well_content h3, .hover_well .well_img h3 {
  color: #fff;
}
.hover_well .well_content {
  color: #fff;
}
.hover_well .well_content h3 {
  color: #fff;
}
.hover_well .well-btn {
  border: 1px solid #fff;
  transform: skew(-25deg);
  padding: 1em;
  max-width: 85%;
  text-align: center;
  align-self: center;
}
.hover_well .well-btn-link {
  color: #fff;
  transform: skew(25deg);
  display: block;
}
.hover_well .well_img .img-fluid {
  width: 80%;
}

.text-over-well {
  position: absolute;
  transform: rotate(50deg) skew(50deg);
  left: 8%;
  top: 29%;
  font-size: 24px;
  letter-spacing: 0.05em;
  font-weight: 600;
}

.list-group-adv {
  width: 32px;
  height: 32px;
}

.well-accordion .card {
  border: none;
  border-radius: none;
}
.well-accordion .card-header {
  background: transparent;
}
.well-accordion .card-header .btn {
  padding-left: 0;
}
.well-accordion .card-title > a {
  text-decoration: none;
  display: block;
}
.well-accordion .card-header a {
  font-size: 1rem;
}
.well-accordion .card-header a::before {
  content: "▼";
  float: right;
  transition: all 0.5s;
}
.well-accordion .card-header.active a::before {
  transform: rotate(180deg);
}
.well-accordion .card-body .well-btn {
  display: block;
  text-align: center;
  padding: 0.5rem 1rem;
  margin: 1.5rem 0;
}
.well-accordion .card-body .well-btn a {
  display: inline-block;
}
.well-accordion .color-advantage-1 .card-link {
  color: #00BF53;
}
.well-accordion .color-advantage-1 .card-header.active {
  background-color: #00BF53;
}
.well-accordion .color-advantage-1 .card-header.active .card-link {
  color: #fff;
}
.well-accordion .color-advantage-1 .card-body .well-btn {
  border: 3px solid #00BF53;
}
.well-accordion .color-advantage-1 .card-body .well-btn a {
  color: #00BF53;
}
.well-accordion .color-advantage-2 .card-link {
  color: #2872E3;
}
.well-accordion .color-advantage-2 .card-header.active {
  background-color: #2872E3;
}
.well-accordion .color-advantage-2 .card-header.active .card-link {
  color: #fff;
}
.well-accordion .color-advantage-2 .card-body .well-btn {
  border: 3px solid #2872E3;
}
.well-accordion .color-advantage-2 .card-body .well-btn a {
  color: #2872E3;
}
.well-accordion .color-advantage-3 .card-link {
  color: #E42D24;
}
.well-accordion .color-advantage-3 .card-header.active {
  background-color: #E42D24;
}
.well-accordion .color-advantage-3 .card-header.active .card-link {
  color: #fff;
}
.well-accordion .color-advantage-3 .card-body .well-btn {
  border: 3px solid #E42D24;
}
.well-accordion .color-advantage-3 .card-body .well-btn a {
  color: #E42D24;
}

.sticky {
  position: fixed;
  width: 100%;
  z-index: 200;
  background: white;
  top: 0;
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.2);
  /*.sticky-hide{
    display: none;
  }
  .sticky-show{
    display: block;
  }*/
}

.nav-advantage-item-link.active {
  background-color: #F29400;
  color: #FFF;
}

.nav-advantage-item-link:hover {
  background-color: #F29400;
  color: #FFF;
}

.dropdown-item:hover {
  background-color: transparent;
}

.header .nav-first:hover {
  background-color: #fff;
}
.header .nav-first:hover .nav-link {
  color: #0072B9;
}

.header .nav-list-item .nav-link:focus {
  background-color: transparent;
  outline: none;
}

.header-search-input:focus, .header-search-input:active, .header-search-input:focus-within, .header-search-input:visited {
  border: none;
  outline: none;
}
.header-search-input::-webkit-search-decoration, .header-search-input::-webkit-search-cancel-button, .header-search-input::-webkit-search-results-button, .header-search-input::-webkit-search-results-decoration {
  display: none;
}

.btn-pr:hover {
  color: #FFF;
  background-color: #0072B9;
}

.download:hover .download-screenshot {
  border-color: #608DBF;
}

.download:hover .download-legende {
  color: #2d81b6;
}

.download:hover .download-picto .st0 {
  fill: #0072B9;
}

.read-more:hover {
  color: #0072B9;
}

.carousel-indicators-shape:hover {
  background: #2d81b6;
  opacity: 1;
}

.adv-clickable:hover li {
  background: #2d81b6;
  cursor: pointer;
  z-index: 0;
}

.striped-gray-3:nth-child(even) {
  background: #C2C2C2;
}

.striped-gray-4:nth-child(odd) {
  background: #F0F0F0;
}

.firstaid-table .row:nth-child(even) {
  background-color: #2d81b6;
}
.firstaid-table .row:nth-child(even) .col-1, .firstaid-table .row:nth-child(even) .col-3 {
  color: #fff;
}

.firstaid-table .row:nth-child(odd) .col-3 {
  color: #212529;
}
.firstaid-table .row:nth-child(odd) .firstaid-table-true {
  background: url("../img/check-green.svg") no-repeat center;
}

@media (max-width: 991.98px) {
  .navbar-collapse {
    position: absolute;
    width: 100%;
    min-height: 100vh;
    max-height: 100vh;
    overflow-y: scroll;
    top: 0;
    z-index: 1031;
    background: #2d81b6;
  }
  .navbar-collapse#navbarNavAltMarkup {
    min-height: auto;
    z-index: 100;
  }

  p, li, button, .fz-default, h5, h6 {
    font-size: 16px;
  }
}
@media (max-width: 1199.98px) {
  .nav-main .navbar-nav .nav-item, .nav-main .navbar-nav .nav-noskew, .nav-main .navbar-nav .nav-dropdown {
    transform: none;
    left: 0;
  }

  .nav-title {
    padding: 0.7rem 0;
  }

  .navbar-advantage-nav li {
    font-size: 1.2em;
  }

  .widget_nav_menu {
    display: flex;
    width: 100%;
  }
  .widget_nav_menu ul {
    display: flex;
    flex-direction: column;
    list-style: none;
    width: 100%;
    padding-left: 0.5rem;
    padding-right: 1rem;
  }
  .widget_nav_menu ul li {
    display: flex;
    justify-content: start;
    text-align: left;
    align-items: center;
    flex-grow: 1;
  }
  .widget_nav_menu ul li a {
    color: #fff;
    padding: 0.7rem 0;
  }

  .navbar-advantage .navbar-collapse {
    height: auto;
    top: 100%;
    border-bottom: 3px solid #F29400;
    background: #fff;
  }
  .navbar-advantage .navbar-toggler {
    font-size: 1rem;
  }
  .navbar-advantage .navbar-toggler .chevron::before {
    border-style: solid;
    border-width: 0.25em 0.25em 0 0;
    content: "";
    display: inline-block;
    height: 0.9em;
    left: 0;
    top: 0.25em;
    position: relative;
    transform: rotate(-45deg);
    vertical-align: top;
    width: 0.9em;
    transition: all 300ms;
  }
  .navbar-advantage .navbar-toggler.collapsed .chevron::before {
    transform: rotate(135deg);
    top: -0.25em;
  }

  .header .nav-dropdown {
    width: 100%;
  }
  .header .nav-link {
    white-space: normal;
    max-width: 90%;
  }

  .dropdown .plus:after {
    content: "+";
  }
  .dropdown.show .plus:after {
    content: "−";
  }
  .dropdown.show .dropdown-item .plus:after {
    content: "+";
  }
  .dropdown.show .dropdown-item.show .plus:after {
    content: "−";
  }

  .dropdown-toggle:after {
    display: none;
  }
  .dropdown-toggle .plus:after {
    font-size: 1.6rem;
    line-height: 3rem;
    border: none;
    margin: 0;
    position: absolute;
    right: 0.5em;
    top: 0;
  }

  #footer {
    overflow: hidden;
  }
}
.sidebar-left {
  max-width: 255px;
}
.sidebar-left .widget-gas {
  max-width: 255px;
}

@media (min-width: 992px) {
  #content {
    margin-top: 136px;
  }

  #cookie-notice {
    margin-bottom: 0;
  }

  #menu-left {
    padding-right: 0;
  }

  .sidebar-left {
    max-width: 233px;
  }
  .sidebar-left .widget-gas {
    max-width: 233px;
  }

  .nav-main .nav-item {
    transform: skew(-30deg);
  }
  .nav-main .nav-item.nav-first {
    background-color: #0072B9;
  }
  .nav-main .nav-item.nav-first a {
    color: #fff;
  }
  .nav-main .nav-item.nav-first:hover {
    background-color: #fff;
  }
  .nav-main .nav-skew {
    width: 121%;
    margin-left: -12%;
  }
  .nav-main .nav-noskew {
    transform: skew(30deg);
  }
  .nav-main .nav-dropdown {
    transform: skew(30deg);
  }
  .nav-main .nav-list-item {
    border-radius: 0;
    border-bottom: 1px solid white;
    font-size: 100%;
    margin-bottom: 0;
  }
  .nav-main .nav-list-item .nav-link {
    padding-right: 2.5rem;
    text-align: left;
  }

  .nav-skew {
    width: 121%;
    margin-left: -12%;
  }

  .menu-item:hover {
    background-color: #0072B9;
  }

  .nav-main-item:hover, .nav-list-item:hover {
    background-color: #0072B9;
  }

  .navbar-collapse {
    min-height: auto;
  }

  .link-card .card-image {
    max-height: 16.4em;
  }
  .link-card .card .triangle {
    border-width: 25em 10em 0 0;
  }

  .title-advantage {
    display: none;
  }

  .footer {
    background: #0072B9;
  }
}
@media (min-width: 1200px) {
  .sidebar-left {
    max-width: 255px;
  }
  .sidebar-left .widget-gas {
    max-width: 255px;
  }

  .page-template-trigor {
    overflow-x: hidden;
  }

  .title-advantage {
    display: block;
  }
}
@media all and (-ms-high-contrast: none) {
  .card {
    display: block !important;
  }

  /* IE10 */
  img {
    flex-shrink: 0;
  }

  .link-card .card-image {
    max-height: 14.9em;
  }

  *::-ms-backdrop, .card {
    display: block !important;
  }

  /* IE11 */
  *::-ms-backdrop, .link-card .card-image {
    max-height: 14.9em;
  }

  /* IE11 */
  *::-ms-backdrop, img {
    flex-shrink: 0;
  }

  /* IE11 */
}