.striped-gray-3:nth-child(even) {
  background: $pr-gray-3;
}
.striped-gray-4:nth-child(odd) {
  background: $pr-gray-4;
}
.firstaid-table .row:nth-child(even){
  background-color: $pr-primary;
  .col-1, .col-3{
    color: $white;
  }
}
.firstaid-table .row:nth-child(odd){
  .col-3{
    color: $pr-default;
  }
  .firstaid-table-true{
      background: url("../img/check-green.svg") no-repeat center;
  }
}