@include media-breakpoint-down(md) {
  .navbar-collapse{
    position: absolute;
    width: 100%;
    min-height: 100vh;
    max-height: 100vh;
    overflow-y: scroll;
    top: 0;
    z-index: 1031;
    background: $pr-primary;
    &#navbarNavAltMarkup{
      min-height: auto;
      z-index: 100;
    }
  }
  p, li, button, .fz-default, h5, h6{
    font-size: 16px;
  }
}