#sectorsCarousel{
  .sector-item{
    .sector-block{
      &:hover{
        .rectangle {
          opacity: 1;
          width: 100%;
        }
        .triangle {
          background-image: linear-gradient(to right bottom, rgba($pr-primary, 1) 0%, rgba($pr-primary, 1) 50%, transparent 50%);
        }
      }
      h5{
        position: absolute;
        color: $white;
        bottom: 0;
        right: 0;
        padding: .2rem .5rem;
      }
      .triangle {
        background-image: linear-gradient(to right bottom, rgba($pr-primary, .6) 0%, rgba($pr-primary, .6) 50%, transparent 50%);
        position: absolute;
        width: 36%;
        height: 100%;
        top: 0;
        left: 0;
        transition: background 600ms ease-out;
        z-index: 2;
      }
      .rectangle {
        z-index: 1;
        background-color: rgba($pr-primary, .4);
        position: absolute;
        width: 0;
        height: 100%;
        top: 0;
        left: 0;
        opacity: 0;
        transition: width 300ms ease-out;
      }
    }
  }

  .carousel-inner {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    .carousel-item{
      position: absolute;
      transform: translate3d(0, 0, 0);
      &.active{
        position: relative;
        + .carousel-item{
          @media (min-width: 576px){
            position: relative;
          }
          + .carousel-item{
            @media (min-width: 768px){
              position: relative;
            }
            + .carousel-item{
              @media (min-width: 991px){
                position: relative;
              }
            }
          }
        }
      }
    }
  }

  @media (min-width: 768px) and (max-width: 991px) {
    /* Show 4th slide on md if col-md-4*/
    .carousel-inner .active.col-md-4.carousel-item + .carousel-item + .carousel-item + .carousel-item {
      position: absolute;
      top: 0;
      right: -33.3333%;  /*change this with javascript in the future*/
      z-index: -1;
      display: block;
      //visibility: visible;
    }
  }
  @media (min-width: 576px) and (max-width: 768px) {
    /* Show 3rd slide on sm if col-sm-6*/
    .carousel-inner .active.col-sm-6.carousel-item + .carousel-item + .carousel-item {
      position: absolute;
      top: 0;
      right: -50%;  /*change this with javascript in the future*/
      z-index: -1;
      display: block;
      //visibility: visible;
    }
  }
  @media (min-width: 576px) {
    .carousel-item {
      margin-right: 0;
    }
    /* show 2 items */
    .carousel-inner .active + .carousel-item {
      display: block;
    }
    .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left),
    .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left) + .carousel-item {
      transition: none;
    }
    .carousel-inner .carousel-item-next {
      position: relative;
      transform: translate3d(0, 0, 0);
    }
    /* left or forward direction */
    .active.carousel-item-left + .carousel-item-next.carousel-item-left,
    .carousel-item-next.carousel-item-left + .carousel-item,
    .carousel-item-next.carousel-item-left + .carousel-item + .carousel-item{
      position: relative;
      transform: translate3d(-100%, 0, 0);
      //visibility: visible;
    }
    /* farthest right hidden item must be also positioned for animations */
    .carousel-inner .carousel-item-prev.carousel-item-right {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      display: block;
      //visibility: visible;
    }
    /* right or prev direction */
    .active.carousel-item-right + .carousel-item-prev.carousel-item-right,
    .carousel-item-prev.carousel-item-right + .carousel-item,
    .carousel-item-prev.carousel-item-right + .carousel-item + .carousel-item {
      position: relative;
      transform: translate3d(100%, 0, 0);
      display: block;
      //visibility: visible;
    }
  }
  /* MD */
  @media (min-width: 768px) {
    /* show 3rd of 3 item slide */
    .carousel-inner .active + .carousel-item + .carousel-item {
      display: block;
    }
    .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left) + .carousel-item + .carousel-item {
      transition: none;
    }
    .carousel-inner .carousel-item-next {
      position: relative;
      transform: translate3d(0, 0, 0);
    }
    /* left or forward direction */
    .carousel-item-next.carousel-item-left + .carousel-item + .carousel-item + .carousel-item {
      position: relative;
      transform: translate3d(-100%, 0, 0);
      //visibility: visible;
    }
    /* right or prev direction */
    .carousel-item-prev.carousel-item-right + .carousel-item + .carousel-item + .carousel-item {
      position: relative;
      transform: translate3d(100%, 0, 0);
      //visibility: visible;
      display: block;
    }
  }
  /* LG */
  @media (min-width: 991px) {
    /* show 4th item */
    .carousel-inner .active + .carousel-item + .carousel-item + .carousel-item {
      display: block;
    }
    .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left) + .carousel-item + .carousel-item + .carousel-item {
      transition: none;
    }
    /* Show 5th slide on lg if col-lg-3 */
    .carousel-inner .active.col-lg-3.carousel-item + .carousel-item + .carousel-item + .carousel-item + .carousel-item {
      position: absolute;
      top: 0;
      right: -25%;  /*change this with javascript in the future*/
      z-index: -1;
      display: block;
      //visibility: visible;
    }
    /* left or forward direction */
    .carousel-item-next.carousel-item-left + .carousel-item + .carousel-item + .carousel-item + .carousel-item {
      position: relative;
      transform: translate3d(-100%, 0, 0);
      //visibility: visible;
    }
    /* right or prev direction //t - previous slide direction last item animation fix */
    .carousel-item-prev.carousel-item-right + .carousel-item + .carousel-item + .carousel-item + .carousel-item {
      position: relative;
      transform: translate3d(100%, 0, 0);
      //visibility: visible;
      display: block;
    }
  }
}

.galeryModal{
  .modal-content{
    border: none;
    border-radius: 0;
  }
  .close{
    position: absolute;
    z-index: 3;
    right: .5em;
    top: .3em;
    color: $pr-prevor;
  }
  .card{
    border-radius: 0;
    border: none;
  }
  .carouselGalery{
    .carousel-item{
      max-height: 80vh;
      img{
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
}

.MultiCarousel {
  float: left;
  overflow: hidden;
  padding: 15px;
  width: 100%;
  position:relative;
  .MultiCarousel-inner {
    transition: 1s ease all;
    float: left;
    .item {
      float: left;
      padding: 0 7px;
      > div {
        text-align: center;
        padding:10px;
        margin:10px;
        background:#f1f1f1;
        color:#666;
      }
    }
  }
  .carousel-control{
    &.over{
      opacity: .1;
      cursor: default;
      &:hover{
        opacity: .1;
      }
    }
  }
}

.MultiCarousel .MultiCarousel-inner .item { float: left; padding: 0 7px}
.MultiCarousel .MultiCarousel-inner .item > div { text-align: center; padding:10px; margin:10px; background:#f1f1f1; color:#666;}